import { AxiosError, AxiosResponse } from "axios";
import React, { ReactNode, useContext, useEffect, useReducer } from "react";
import { createContext } from "react";
import useAuthConnection from "../../Hooks/useAuthConnection";
import { useSnackBar } from "../../Hooks/useSnackBar";
import { IPerson, IPersonDTO } from "../ContactsContext/ContactsProvider";
import { IPause } from "../TimeTrackingContext/TimeTrackingProvider";
import { useUser } from "../UserContext/UserContext";
import StaffReducer from "./StaffReducer";
import PersonReducer from "./StaffReducer";
import { errorHandler } from "../../Connection/BaseConnection";
import { useCompanyData } from "../CompanyDataContext/CompanyDataProvider";
import { autoHideDurationDefault } from "../../Global/Variables";

export interface IStaff extends StaffDTO {
  id: number;
}

export interface StaffDTO {
  id: number;
  username: string;
  eMail: string;
  eMailMFA: boolean;
  organizationId: number;
  sVNR: string;
  base64: string;
  person?: IPerson;
  personId?: number;
  employmentRelation?: EmploymentRelationDTO;
  password?: string;
  workingStatus?: 0 | 1 | 2;
  active?: boolean;
  deleted?: boolean;
  reveivesNewsletter?: boolean;
  organizationRoleName?: string;
  organizationRoleId?: number;
  employmentRelationId?: number;
  automaticPause?: boolean;
  unusedHolidays?: number;
  takesPicturesForServices?: boolean;
}

export interface EmploymentRelationDTO {
  id?: number;
  hours?: number;
  hourlyRate?: number;
  salary?: number;
  from?: Date;
  to?: Date;
  organizationRoleId?: number;
  organizationId?: number;
  userId?: number;
}

export const defaultEmployementRelation: EmploymentRelationDTO = {
  id: 0,
  hours: NaN,
  hourlyRate: NaN,
  salary: NaN,
};

interface IStaffContext {
  staff: StaffDTO[];
  defaultStaff?: StaffDTO;
  fetchStaff?: () => Promise<void>;
  addStaff?: (staff: StaffDTO, setError?: any) => Promise<void>;
  updateStaff?: (staff: StaffDTO) => Promise<void>;
  deleteStaff?: (staff: StaffDTO) => Promise<void>;
  setWorkingStateOfAll?: (workingStateOfAll: any) => void;
}

const StaffContext = createContext<IStaffContext>({
  staff: [],
});

export let defaultStaff: StaffDTO = {
  base64: "",
  eMail: "",
  eMailMFA: false,
  id: 0,
  organizationId: 0,
  sVNR: "",
  username: "",
  unusedHolidays: NaN,
  employmentRelation: defaultEmployementRelation,
};

const StaffProvider = ({ children }: { children: ReactNode }) => {
  const { companyData } = useCompanyData();

  useEffect(() => {
    if (companyData.id) {
      defaultStaff.organizationId = companyData.id;
    }
  }, [companyData]);

  const connection = useAuthConnection();

  const { enqueueSnackbar, closeSnackbar } = useSnackBar();

  useEffect(() => {
    fetchStaff();
    fetchWorktingStates();
  }, []);

  const [state, dispatch] = useReducer(StaffReducer, {
    staff: [],
  });

  const fetchWorktingStates = async () => {
    connection.get("/worktime/WorkingStateOfAll").then((res: AxiosResponse) => {
      dispatch({
        type: "SET_WORKINGSTATES",
        payload: res.data,
      });
    });
  };

  const setWorkingStateOfAll = async (workingStateOfAll: any) => {
    dispatch({
      type: "SET_WORKINGSTATES",
      payload: workingStateOfAll,
    });
  };

  const fetchStaff = async () => {
    connection
      .get("/user")
      .then((res: AxiosResponse) => {
        dispatch({
          type: "SET_STAFF",
          payload: res.data,
        });

        res.data.forEach((element: IStaff) => {
          connection
            .get(`/user/profilepicture/${element.id}`)
            .then((res: AxiosResponse) => {
              dispatch({
                type: "SET_PB",
                payload: { base64: res.data, id: element.id },
              });
            });
        });
      })
      .catch((error) => {
        setTimeout(() => {
          fetchStaff();
        }, 2500);
      });
  };

  const addStaff = async (staff: StaffDTO, setError?: any) => {
    let x = enqueueSnackbar("Mitarbeiter wird erstellt", {
      variant: "default",
      autoHideDuration: autoHideDurationDefault,
    });

    try {
      const res = await connection.post("/user/", { ...staff });

      closeSnackbar(x);
      enqueueSnackbar("Mitarbeiter erfolgreich erstellt", {
        variant: "success",
      });

      dispatch({
        type: "ADD_STAFF",
        payload: res.data,
      });
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 409) {
        //409 = UsernameAlreadyExistsException
        if (setError) {
          setError((old: any) => ({ ...old, usernameAlreadyExists: true }));
        }
      }
      errorHandler(error, x, enqueueSnackbar, closeSnackbar);
      throw error;
    }
  };

  const updateStaff = async (staff: StaffDTO) => {
    let x = enqueueSnackbar("Mitarbeiter wird gespeichert", {
      variant: "default",
      autoHideDuration: autoHideDurationDefault,
    });

    try {
      const res = await connection.put("/user/", { ...staff });
      closeSnackbar(x);
      enqueueSnackbar("Mitarbeiter erfolgreich bearbeitet", {
        variant: "success",
      });

      dispatch({
        type: "UPDATE_STAFF",
        payload: res.data,
      });
    } catch (error) {
      errorHandler(error, x, enqueueSnackbar, closeSnackbar);
      throw error;
    }
  };

  const deleteStaff = async (staff: StaffDTO) => {
    let x = enqueueSnackbar("Mitarbeiter wird entfernt", {
      variant: "default",
      autoHideDuration: autoHideDurationDefault,
    });
    staff.deleted = true;
    staff.active = false;
    connection
      .put("/user/", { ...staff })
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Mitarbeiter erfolgreich entfernt", {
          variant: "success",
        });

        dispatch({
          type: "DELETE_STAFF",
          payload: staff,
        });
      })
      .catch((error: any) => {
        errorHandler(error, x, enqueueSnackbar, closeSnackbar);
      });
  };

  return (
    <StaffContext.Provider
      value={{
        staff: state.staff,
        fetchStaff,
        addStaff,
        updateStaff,
        deleteStaff,
        setWorkingStateOfAll,
        defaultStaff,
      }}
    >
      {children}
    </StaffContext.Provider>
  );
};

export default StaffProvider;

export const useStaff = () => useContext(StaffContext);
