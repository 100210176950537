import React, { Dispatch, SetStateAction, useState } from 'react';
import styles from './BillsCard.module.css';

//Icons
import { Collapse } from '@mui/material';

import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider';
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { StaffDTO, useStaff } from '../../Contexts/StaffContext/StaffProvider';
import { userFormModes } from '../../Pages/Verwaltung/Personal/Personal';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';

interface props {
    staff: StaffDTO
    setClickedUser?: Dispatch<SetStateAction<StaffDTO>>
    setFormMode?: Dispatch<SetStateAction<userFormModes>>
}

const PersonalRow = ({ staff, setClickedUser, setFormMode }: props) => {
    let navigate = useNavigate();

    const { deleteStaff, defaultStaff } = useStaff();
    const dialogContext = useModals();

    const displayName = staff.person?.lastname ? (staff.person.firstname ? ((staff.person.title ? staff.person.title + " " : "") + staff.person.firstname + " " + staff.person.lastname) : (staff.person.lastname)) : staff.username;

    const onPressEdit = () => {
        dialogContext.changeVisibility!("editCreatePersonal", true, { props: { object: staff, mode: "edit" } });
    }

    const onPressDelete = () => {
        dialogContext.changeVisibility!("yesNoDialog", true, { props: { object: { modalTitle: "Mitarbeiter löschen", submitFunction: () => deleteStaff!(staff), confirmButtonText: "Löschen", modalText: "Möchten Sie diesen Mitarbeiter wirklich löschen?" } } })
    }

    const onPressGenerateWorktimeOverview = () => {
        navigate(`/verwaltung/personal/arbeitszeituebersicht/erstellen/${staff.id}`)
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        },
        {
            text: "Arbeitszeitübersicht generieren",
            function: onPressGenerateWorktimeOverview
        }
    ]

    const [expanded, setExpanded] = useState<boolean>(false);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const { companies } = useContacts();
    const { companyData } = useCompanyData();

    const handleExpand = () => {
        if (setFormMode && setClickedUser) {
            if (!expanded === true) {
                setClickedUser(staff);
                setFormMode("editUser");
            }
            else {
                setClickedUser(defaultStaff!);
                setFormMode("createUser");
            }
        }
        setExpanded(!expanded);
    }

    return (
        <>
            {staff &&
                <div className={'flex column '.concat(styles.container)} style={{ backgroundColor: "white" }} >
                    <header className='flex centered-aligned stretched-justify'>
                        <div className='flex centered-aligned pointer' onClick={() => handleExpand()} style={{ gap: "6px" }}>
                            <div className={(staff.workingStatus === 0) ? styles.accent : (staff.workingStatus === 1 ? styles.working : styles.pause)} />
                            {!expanded ? <ExpandMoreRounded fontSize='small' /> : <ExpandLessRounded fontSize='small' />}
                            <span className='bold-big'>{displayName} {staff.deleted ? "(Gelöscht)" : !staff.active && " (Inaktiv)"}</span>
                        </div>
                        <MoreOptionsButton options={options}></MoreOptionsButton>
                    </header>
                    <Collapse in={expanded}>
                        <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                            <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{"Username: " + (staff.username)}</span>
                            </div>
                        </section>
                        <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                            <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{"E-Mail: " + (staff.eMail ?? "")}</span>
                            </div>
                        </section>
                        <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                            <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{"Berechtigungsrolle: " + (staff.organizationRoleName ?? "")}</span>
                            </div>
                        </section>
                        {companyData.id !== staff.organizationId &&
                            <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                                <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                    <span style={{ textOverflow: "ellipsis" }}>{"Gehört zu Firma: " + (companies.find(temp => temp.id === staff.organizationId)?.name)}</span>
                                </div>
                            </section>
                        }
                    </Collapse>
                </div>
            }
        </>
    )
}

export default PersonalRow;