import { SearchRounded } from "@mui/icons-material";
import { Input } from "../UI/Input";
import { memo, useState, useCallback, useEffect } from "react";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";

interface SearchInputProps {
  onSearch: (searchValue: string) => void;
  searchIsLoading: boolean;
  setPage: (page: number) => void;
}

const SearchInput = memo(
  ({ onSearch, searchIsLoading, setPage }: SearchInputProps) => {
    const [searchWord, setSearchWord] = useState("");

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchWord(e.target.value);
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        triggerSearch(searchWord);
      }
    };

    const triggerSearch = (search: string) => {
      setPage(1);
      onSearch(search);
    };

    // Detect when the search input is cleared and trigger search
    useEffect(() => {
      if (searchWord === "") {
        triggerSearch(""); // Automatically trigger search when input is cleared
      }
    }, [searchWord]);

    return (
      <div className="flex flex-row w-full items-center CollapsibleContent pb-2 pt-2">
        <div className="w-[85%]">
          <Input
            autoFocus
            value={searchWord}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
          />
        </div>
        <div className="flex w-[15%] items-center justify-center">
          {!searchIsLoading ? (
            <Tooltip title="Suchen">
              <IconButton onClick={() => triggerSearch(searchWord)}>
                <SearchRounded />
              </IconButton>
            </Tooltip>
          ) : (
            <CircularProgress size={16} />
          )}
        </div>
      </div>
    );
  }
);

export default SearchInput;
