import { AxiosResponse } from 'axios'
import React, { ReactNode, useContext, useEffect, useReducer } from 'react'
import { createContext } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import ProductReducer from './ProductReducer'
import { errorHandler } from '../../Connection/BaseConnection'
import { autoHideDurationDefault, autoHideDurationSuccess } from '../../Global/Variables'

export interface IProductDTO {
    id: number
    title?: string
    description?: string
    cost?: number
    salesPrice?: number
    note?: string
    ust?: number
    count?: number
    organizationId?: number
    codeValue?: string
    isQrCode?: boolean
    labelLength?: number
    labelWidth?: number
}

interface IProductContext {
    products: IProductDTO[],
    updateProduct: (product: IProductDTO) => Promise<void>,
    addProduct: (product: IProductDTO) => Promise<void>,
    removeProduct: any,
    fetchProducts: any
}

export const defaultProduct: IProductDTO = {
    id: 0,
    title: "",
    description: "",
    cost: NaN,
    salesPrice: NaN,
    ust: NaN,
    count: NaN,
    codeValue: "",
    isQrCode: false,
    labelLength: NaN,
    labelWidth: NaN
}

const ProductContext = createContext<IProductContext>({
    products: [],
    updateProduct: async () => { },
    addProduct: async () => { },
    removeProduct: () => { },
    fetchProducts: () => { },
})

const ProductProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(ProductReducer, {
        products: []
    })

    const connection = useAuthConnection();
    const { enqueueSnackbar, closeSnackbar } = useSnackBar();

    const setProducts = (products: IProductDTO[]) => {
        dispatch({
            type: "SET_PRODUCTS",
            payload: products
        })
    }

    const addProduct = async (product: IProductDTO) => {
        let x = enqueueSnackbar("Produkt wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        try {
            const res = await connection.post("/product", product); // Await the API call
    
            closeSnackbar(x);
            enqueueSnackbar("Produkt erfolgreich erstellt", { variant: "success", autoHideDuration: autoHideDurationSuccess });
    
            dispatch({
                type: "ADD_PRODUCT",
                payload: res.data
            });
    
        } catch (error) {
            errorHandler(error, x, enqueueSnackbar, closeSnackbar);
            throw error; // Ensure the error propagates up
        }
    }

    const updateProduct = async (product: IProductDTO): Promise<void> => { 
        let x = enqueueSnackbar("Produkt wird bearbeitet", { variant: "default", autoHideDuration: autoHideDurationDefault });
    
        try {
            const res = await connection.put("/product", product); // Await API request
    
            closeSnackbar(x);
            enqueueSnackbar("Produkt erfolgreich bearbeitet", { variant: "success", autoHideDuration: autoHideDurationSuccess });
    
            dispatch({
                type: "UPDATE_PRODUCT",
                payload: res.data
            });
    
        } catch (error) {
            errorHandler(error, x, enqueueSnackbar, closeSnackbar);
            throw error; // Ensure error propagates for further handling
        }
    };
    

    const removeProduct = (product: IProductDTO) => {
        let x = enqueueSnackbar("Produkt wird entfernt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.delete("/product", { data: product })
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Produkt erfolgreich entfernt", { variant: "success", autoHideDuration: autoHideDurationSuccess })

                dispatch({
                    type: "REMOVE_PRODUCT",
                    payload: product
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const fetchProducts = () => {
        connection.get("/product").then((res: AxiosResponse) => {
            setProducts(res.data);
        })
    }

    useEffect(() => { fetchProducts() }, [])

    return (
        <ProductContext.Provider
            value={{
                products: state.products,
                updateProduct: updateProduct,
                addProduct: addProduct,
                removeProduct: removeProduct,
                fetchProducts: fetchProducts
            }}
        >
            {children}
        </ProductContext.Provider>
    )
}

export default ProductProvider

export const useProducts = () => useContext(ProductContext)