import React, { ReactNode, useEffect } from "react";
import { getClassNames } from "../../Hooks/Utilities/Utlitites";
import styles from "./SearchableItem.module.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Fade, Grow } from "@mui/material";

//TODO any ausbessern -> Fabian bzw. wer Interesse hat
interface searchableSelect {
  title: ReactNode;
  onClick?: () => void;
  active?: boolean;
  value?: any;
  arrowKeyHovered?: boolean;
  isSelectable?: boolean;
}

const SearchableItem = ({
  title,
  active,
  onClick,
  arrowKeyHovered,
  isSelectable,
}: searchableSelect) => {
  return (
    <div
      onClick={onClick}
      className={
        !arrowKeyHovered
          ? getClassNames([styles.container, "flex", "stretched-justify"])
          : getClassNames([
              styles.containerArrowHover,
              "flex",
              "stretched-justify",
            ])
      }
    >
      <span
        className={`${
          !isSelectable ? "select-none text-gray-400 cursor-not-allowed" : ""
        }`}
      >
        {title}
      </span>
      <Grow in={active}>
        <CheckCircleRoundedIcon color="success" fontSize="inherit" />
      </Grow>
    </div>
  );
};

export default SearchableItem;
