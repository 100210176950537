import { AxiosResponse } from 'axios'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { UpdateUserDTO } from '../../Components/PersonalSettingsCard/PersonalSettingsCard'
import { baseConnection } from '../../Connection/BaseConnection'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useLocalStorage } from '../../Hooks/useLocalStorage'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { User } from '../../Interfaces/User'
import { UserContext } from '../../Interfaces/UserContext'
import { useOrganizationRights } from '../OrganizationRightsContext/OrganizationRightsProvider'
import userReducer from './UserReducer'
import { autoHideDurationDefault } from '../../Global/Variables'
import posthog from 'posthog-js'


//Initalisieren des Kontexts
const userContext = createContext<UserContext>({
  user: null,
  accounts: []
})


//Wrapper für den Kontext -> Alle Kind-Elemente können auf die Daten in diesem Wrapper zugreifen.
export function UserProvider({ children }: { children: JSX.Element }) {

  const { enqueueSnackbar, closeSnackbar } = useSnackBar()

  const { organizationRoles } = useOrganizationRights();

  //Vorübergehende Speicherung des RefreshTokens
  const [token, setToken] = useLocalStorage("token", "")

  //Accounts, die in der Vergangenheit von diesem Nutzer benutzt wurden. Werden über den Custom-Hook aktualisiert und abgerufen.
  const [accounts, setAccounts] = useLocalStorage("accounts", [])

  //Komplexer State für den Kontext -> Good Practice für die Arbeit mit Context
  const [state, dispatch] = useReducer(userReducer, {
    user: null,
    accounts: []
  })

  const setProfilePicture = (pb64: any) => {
    dispatch({
      type: "SET_PROFILEPICTURE",
      payload: pb64
    });
  }

  const setUser = (newUser: User | null) => {
    if (newUser !== null && newUser !== undefined) {
      dispatch({
        type: "SET_USER",
        payload: { ...newUser, userId: newUser?.userId ?? 9 }
      })
    } else {
      dispatch({
        type: "SET_USER",
        payload: null
      })
    }
  }

  const updatePersonalSettings = async (user: UpdateUserDTO) => {
    let key = enqueueSnackbar("Einstellungen werden geändert", { autoHideDuration: autoHideDurationDefault })
    try {
      const { data } = await connection.put("/User/UpdatePersonalSettings", user);

      const { birthdate, emailMFA, receivesNewsletter, svnr, voipAddress, automaticPause } = data;

      dispatch({
        type: "UPDATE_USER_SETTINGS",
        payload: {
          birthdate,
          emailMFA,
          receivesNewsletter,
          svnr,
          voipAddress,
          automaticPause
        }
      })
      closeSnackbar(key);
      enqueueSnackbar("Einstellungen wurden erfolgreich aktualisiert", { variant: 'success' });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Fehler beim aktualisieren", { variant: 'error' });
    }
  }

  const connection = useAuthConnection();

  //! Struktur der API wird noch geändert
  //TODO Sollte auch noch das Speichern des Accounts bei erfolgreicher Neu-Anmeldung übernehmen -> Fabian
  const login = async (username: string, password: string) => {
    await baseConnection.post("/Authentication/SignIn", {
      username,
      password
    }, {
      withCredentials: true
    })
      .then((res: AxiosResponse) => {
        const { data } = res;
        setToken(data.token)

        if (data.token) {
          localStorage.setItem("AccessToken", data.token);
        }

        //Ändert den komplexen State der User-Verwaltung
        setUser({
          username: username,
          email: data.eMail, //!
          ...data
        })

        if (res.status >= 200 && res.status < 300) {
          enqueueSnackbar("Lass uns loslegen", { variant: "success" })
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);


            //Wenn Passwort oder Username falsch
            enqueueSnackbar(error.response.data ? error.response.data : "Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline: +43 463 890 133", { variant: "warning" })
          
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js

          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  useEffect(() => { console.log("User", state.user) }, [state.user])

  const logout = () => {
    baseConnection.post("/authentication/signout", null, { withCredentials: true })
      .then((res: AxiosResponse) => {
        console.log("status === 200", res.status === 200)
        if (res.status === 200) {
          setUser(null);
          localStorage.clear();
          posthog.reset();
        }
      })
  }

  const setUserWorkingOnTask = (taskId: number | undefined) => {
    console.log(taskId)
    setUser({ ...state.user, workingOnTask: taskId })
  }

  //Der schließliche Wrapper wird durch den Provider bereitgestellt.
  return (
    <userContext.Provider value={{
      user: state.user,
      token,
      setToken,
      setProfilePicture,
      accounts,
      login,
      logout,
      setUser,
      updatePersonalSettings,
      setUserWorkingOnTask
    }}>
      {children}
    </userContext.Provider>
  )
}


//Ermöglicht den erleichterten Zugang zu den Daten im Kontext in den Kind-Elementen.
export const useUser = () => useContext(userContext)