import React, { useState, useEffect } from "react";

import { StaffDTO, useStaff } from "../../Contexts/StaffContext/StaffProvider";
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored";
import FormRow from "../Forms/FormUtils/FormRow";

export interface StaffSelectProps {
  selectedStaffIds: number[];
  onChange: (ids: number[]) => void;
  error?: boolean;
  errorMessage?: string;
  multiple?: boolean;
}

const transformStaffOptions = (staff: StaffDTO[]) => {
  const tempOptions = staff.map((employee) => ({
    id: employee.id,
    username: employee.active
      ? employee.username
      : employee.deleted
      ? `${employee.username} (gelöscht)`
      : `${employee.username} (inaktiv)`,
    isSelectable: (employee.active && !employee.deleted) ?? true,
  }));

  // Sort active employees first, then alphabetically (ignoring the suffix)
  tempOptions.sort((a, b) => {
    if (a.isSelectable && !b.isSelectable) return -1;
    if (!a.isSelectable && b.isSelectable) return 1;

    const nameA = a.username
      .replace(" (inaktiv)", "")
      .replace(" (gelöscht)", "");
    const nameB = b.username
      .replace(" (inaktiv)", "")
      .replace(" (gelöscht)", "");
    return nameA.localeCompare(nameB);
  });

  return tempOptions;
};

const StaffSelectRow: React.FC<StaffSelectProps> = ({
  selectedStaffIds,
  onChange,
  error,
  errorMessage = InputFieldErrorMessages.EmployeeRequired,
  multiple = true
}) => {
  const { staff } = useStaff();
  const [staffOptions, setStaffOptions] = useState(
    transformStaffOptions(staff)
  );

  useEffect(() => {
    setStaffOptions(transformStaffOptions(staff));
  }, [staff]);

  return (
    <FormRow
      type="select"
      label="Mitarbeiter*innen"
      placeholder="Mitarbeiter*innen auswählen"
      value={selectedStaffIds}
      onChange={onChange}
      selectOptions={{
        titleKey: "username",
        valueKey: "id",
        options: staffOptions,
        multiple: multiple
      }}
      error={error}
      errorMessage={errorMessage}
    />
  );
};

export default StaffSelectRow;
