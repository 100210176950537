import { useEffect, useState } from "react";
import { ITravel } from "../../Interfaces/Travel";
import { useGetAllTravelsQuery } from "../../redux/apiSlices/Travel";
import FormRow from "../Forms/FormUtils/FormRow";
import { IconButton, Tooltip } from "@mui/material";
import { Plus } from "lucide-react";
import { useModals } from "../../Contexts/DialogContext/DialogProvider";

export type TravelSelectProps = {
  onSelectionChange: (selectedTravels: ITravel[]) => void;
  initialSelectedTravels: ITravel[];
};
export default function TravelSelect({
  onSelectionChange,
  initialSelectedTravels,
}: TravelSelectProps) {
  const { data: travels, isLoading } = useGetAllTravelsQuery();

  const [selectedTravelIds, setSelectedTravelIds] = useState<number[]>([]);

  useEffect(() => {
    if (selectedTravelIds.length > 0) {
      let tempSelectedTravels: ITravel[] = [];
      selectedTravelIds.forEach((id) => {
        const travel = travels?.find((t) => t.id === id);

        if (travel) tempSelectedTravels.push(travel);
      });
      onSelectionChange(tempSelectedTravels);
    }
  }, [selectedTravelIds]);

  useEffect(() => {
    if (
      initialSelectedTravels &&
      initialSelectedTravels.length > 0 &&
      selectedTravelIds.length === 0
    ) {
      setSelectedTravelIds(initialSelectedTravels.map((t) => t.id));
    }
  }, [initialSelectedTravels]);

  const {changeVisibility} = useModals();
  return (
    <>
      {!isLoading && (
        <div className="flex flex-row gap-4 items-center">
          <FormRow
            label="Fahrt zuordnen"
            type="select"
            value={selectedTravelIds}
            onChange={(val) => {
              onSelectionChange(val);
              setSelectedTravelIds(val);
            }}
            selectOptions={{
              options: travels ?? [],
              titleKey: "title",
              valueKey: "id",
              multiple: true,
            }}
          />
          <div className="mt-4 w-[10%]">
            <Tooltip title="Neue Fahrt erstellen">
              <IconButton onClick={() => changeVisibility!("editCreateTravel", true, {props: {mode: "create"}})}>
                <Plus />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
}
