import { useState } from "react";
import FormRow from "../Forms/FormUtils/FormRow";
import {
  defaultProduct,
  IProductDTO,
  useProducts,
} from "../../Contexts/ProductContext/ProductProvider";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import { IServiceDTO } from "../../Interfaces/Service";
import ProductRow from "../../Pages/Services/ProductRow";
import { IconButton, Tooltip } from "@mui/material";
import { Plus } from "lucide-react";
import EditCreateProductDialog from "../Dialogs/EditCreateProductDialog/EditCreateProductDialog";
import { useModals } from "../../Contexts/DialogContext/DialogProvider";

export default function AddProductFormRow({
  object,
  setObject,
}: {
  object: any;
  setObject: any;
}) {
  const [newProductId, setNewProductId] = useState<number>();
  const { products } = useProducts();
  const [newProductError, setNewProductError] = useState(false);
  //const [isCreateProductDialogOpen, setIsCreateProductDialogOpen] = useState(false);
  const { changeVisibility } = useModals();
  return (
    <>
      <div className="form-row-label" style={{ marginBottom: "0em" }}>
        <span className="label">Produkte</span>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px",
        }}
      >
        <div className="w-[60%]">
          <FormRow
            label=""
            value={newProductId}
            onChange={(val) => setNewProductId(val)}
            type="select"
            selectOptions={{
              options: products,
              titleKey: "title",
              valueKey: "id",
              multiple: false,
            }}
            error={newProductError}
            errorMessage="Bitte ein Produkt auswählen"
          />
        </div>
        <div className="w-[30%] max-h-[50px]">
          <CTAButton
            style={{ width: "100%", height: "100%" }}
            onClickHandler={() => {
              console.log("newProductId", newProductId);
              if (newProductId === undefined) {
                setNewProductError(true);
                return;
              } else {
                setNewProductError(false);
              }
              setObject((old: IServiceDTO) => {
                let productAlreadyThere = old.products?.find(
                  (product: IProductDTO) => product.id === newProductId
                );
                if (productAlreadyThere !== undefined) {
                  return {
                    ...old,
                    products: old.products!.map((tempProduct: any) => {
                      if (productAlreadyThere!.id === tempProduct.productId) {
                        return {
                          ...tempProduct,
                          count: parseInt(tempProduct.count! + "") + 1,
                        };
                      }
                      return tempProduct;
                    }),
                  };
                }

                let tempProduct = products.find(
                  (product) => product.id === newProductId
                );
                return {
                  ...old,
                  products: [
                    ...(old.products ?? []),
                    {
                      ...tempProduct,
                      productId: tempProduct?.id,
                      id: tempProduct?.id,
                      count: 1,
                      netto: tempProduct?.salesPrice,
                    },
                  ],
                };
              });
            }}
            title={"Produkt hinzufügen"}
          />
        </div>
        <div className="w-[10%]">
          <Tooltip title="Neues Produkt erstellen">
            <IconButton onClick={() => changeVisibility!("editCreateProduct", true, {props: { mode: "create" }})}>
              <Plus />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="form-row-label" style={{ marginBottom: "1em" }}>
        {object.products?.map((product: IProductDTO) => {
          return (
            <>
              <ProductRow
                product={product}
                setProduct={(value: string) => {
                  setObject((old: any) => ({
                    ...old,
                    products: old.products?.map((tempProduct: any) => {
                      if (product.id == tempProduct.id) {
                        return { ...tempProduct, count: parseInt(value) };
                      }
                    }),
                  }));
                }}
                removeProduct={() => {
                  setObject((old: any) => ({
                    ...old,
                    products: old.products?.filter(
                      (tempProduct: IProductDTO) =>
                        tempProduct.id !== product.id
                    ),
                  }));
                }}
              />
            </>
          );
        })}
      </div>
      <EditCreateProductDialog
        open={false}
        mode={"create"}
        object={null}
      />
    </>
  );
}
