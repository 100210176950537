import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import { BillPrefix } from '../../Interfaces/Accounting'
import { IBillListDTO } from '../../Interfaces/Bill'
import { card } from '../../Interfaces/Card'
import Filter, { IFilterOption } from '../Filter/Filter'
import { Card, CardContent, CardFooter, CardFooterButton, CardHeader, CardTitle } from '../UI/CardNew'
import { AddRounded } from '@mui/icons-material'
import ButtonControl from '../Buttons/ButtonControl/ButtonControl'
import BillRow from './BillRow'
import Pagination from '../Pagination/Pagination'

function BillsCard({ width, height, order }: card) {

  const { bills, billPrefixes } = useBills();
  const [filteredBills, setFilteredBills] = useState<IBillListDTO[]>([]);

  const navigate = useNavigate();

  const [mode, setMode] = useState("bills");

  const buttons = [
    {
      body: (active: boolean) => <span className='centered' style={{ gap: "2px", fontSize: "small" }}>Alle</span>,
      value: "all"
    },
    {
      body: (active: boolean) => <span className='centered' style={{ gap: "2px", fontSize: "small" }}>Rechnungen</span>,
      value: "bills"
    },
    {
      body: (custom: boolean) => <span className='centered' style={{ gap: "2px", fontSize: "small" }}>Belege</span>,
      value: "receipts"
    },
    /* ToDo: Add custom filtering
    {
      body: (active: boolean) => active ? <span onClick={() => { }} className='centered' style={{ gap: "2px", fontSize: "small" }}><Tune fontSize='small' /></span> : <Tune fontSize='small' />,
      value: "custom"
    }*/
  ]

  const [nonOfferPrefixes, setNonOfferPrefixes] = useState<Array<BillPrefix>>(billPrefixes.filter(prefix => !prefix.isOffer))

  const originalFilteredBills = useMemo(() => getFilteredBillsForMode(mode, bills, nonOfferPrefixes), [mode, bills, nonOfferPrefixes]);

  useEffect(() => {
    setNonOfferPrefixes(billPrefixes.filter(prefix => !prefix.isOffer))
  }, [billPrefixes])

  useEffect(() => {
    const billsFiltered = bills.filter((billDTO: IBillListDTO) => {
      return !billDTO.bill.isReceipt && nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
    })
    setFilteredBills(billsFiltered);
  }, [bills, nonOfferPrefixes])

  useEffect(() => {
    setFilteredBills(getFilteredBillsForMode(mode, bills, nonOfferPrefixes))
  }, [mode, bills])

  const filterOptions: IFilterOption[] = [
    { title: "Rechnungskreis", valueKey: "bill.billPrefix" },
    { title: "Kunde", valueKey: "bill.customerName" }
  ]

  function getFilteredBillsForMode(mode: string, bills: IBillListDTO[], nonOfferPrefixes: BillPrefix[]) {
    let billsFiltered;
    switch (mode) {
      case "all":
        billsFiltered = bills.filter((billDTO: IBillListDTO) => {
          return nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
        })
        return billsFiltered;
      case "bills":
        billsFiltered = bills.filter((billDTO: IBillListDTO) => {
          return !billDTO.bill.isReceipt && nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
        })
        return billsFiltered;
      case "receipts":
        billsFiltered = bills.filter((billDTO: IBillListDTO) => {
          return billDTO.bill.isReceipt && nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
        })
        return billsFiltered;
      default: //same as case "bills"
        billsFiltered = bills.filter((billDTO: IBillListDTO) => {
          return !billDTO.bill.isReceipt && nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
        })
        return billsFiltered;
    }
  }

  const [page, setPage] = useState(0);
  const billRowContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (billRowContainerRef.current) {
      billRowContainerRef.current.scrollTo(0, 0)
    }
  }, [page])



  return (
    <Card>
      <CardHeader className='justify-between'>
        <CardTitle>Rechnungen</CardTitle>
        <div className='flex flex-row mr-2'>
          <ButtonControl setValue={(val) => setMode(val)} value={mode} buttons={buttons} />
          <Filter dataToFilter={filteredBills} setDataToFilter={setFilteredBills} filterOptions={filterOptions} originalData={originalFilteredBills} />
        </div>

      </CardHeader>
      <CardContent>
        <div ref={billRowContainerRef} className='flex flex-col h-[93%] overflow-auto'>
          {filteredBills.map((billDTO: IBillListDTO, index) => {
            if (index >= (page) * 25 && index < (page + 1) * 25) {
              return (
                <BillRow billDTO={billDTO} key={billDTO.bill.id}/>
              )
            }
          })}
        </div>
        <Pagination items={filteredBills} itemsPerPage={25} page={page} setPage={setPage} />
      </CardContent>
      <CardFooter>
        <CardFooterButton onClick={() => navigate("/rechnungswesen/rechnungen/erstellen")}><AddRounded fontSize='small' />Rechnung erstellen</CardFooterButton>
      </CardFooter>
    </Card>
  )
}

export default BillsCard