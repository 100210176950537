import { useSnackbar } from "notistack";
import { useCreateProjectMutation, useDeleteProjectMutation, useEditProjectMutation } from "../apiSlices/Project";
import { RTKQueryErrorHandler } from "./utils";
import { IProjectDTO } from "../../Contexts/TaskContext/TaskContext";

export const useHandleCreateProject = () => {
    const [createProject, { isLoading, isError, error }] = useCreateProjectMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleCreateProject = async (project: IProjectDTO) => {
        let x = enqueueSnackbar("Projekt wird erstellt", { variant: "default" });

        try {
            const result = await createProject(project).unwrap();
            //Success
            closeSnackbar(x);
            enqueueSnackbar("Projekt erfolgreich erstellt", { variant: "success" });
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" })
        }
    }

    return { handleCreateProject, isLoading }
}

export const useHandleEditProject = () => {
    const [editProject, { isLoading, isError, error }] = useEditProjectMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleEditProject = async (project: IProjectDTO) => {
        let x = enqueueSnackbar("Projekt wird bearbeitet", { variant: "default" });

        try {
            const result = await editProject(project).unwrap();
            closeSnackbar(x);
            enqueueSnackbar("Projekt erfolgreich bearbeitet", { variant: "success" });
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleEditProject, isLoading }
}

export const useHandleDeleteProject = () => {
    const [deleteProject, { isLoading, isError, error }] = useDeleteProjectMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleDeleteProject = async (project: IProjectDTO) => {
        let x = enqueueSnackbar("Projekt wird gelöscht", { variant: "default" });

        try {
            const result = await deleteProject(project);
            closeSnackbar(x);
            enqueueSnackbar("Projekt erfolgreich gelöscht", { variant: "success" });
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    return { handleDeleteProject, isLoading }
}