import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { authConnection } from "../../Connection/BaseConnection";
import FormRow from "../Forms/FormUtils/FormRow";
import { ServiceSummaryChart } from "./ServiceSummaryChart";
import { useGetServiceSummaryForProjectGroupedByMonthQuery } from "../../redux/apiSlices/Project";
import { CircularProgress, Tooltip } from "@mui/material";
import { RotateCw, Check } from "lucide-react";

export default function ProjectFinancesGraph() {
  const { id } = useParams();
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const {
    data: chartData,
    isLoading,
    isFetching,
    refetch,
  } = useGetServiceSummaryForProjectGroupedByMonthQuery(
    {
      projectId: parseInt(id ?? "0"),
      startMonth: startDate ? startDate?.month() + 1 : 0,
      startYear: startDate?.year(),
      endMonth: endDate ? endDate?.month() + 1 : 0,
      endYear: endDate?.year(),
    }
  );

  const handleSubmit = async () => {
    refetch();
  };

  return (
    <div className="flex-1">
      {/* Header */}
      <div className="flex flex-col gap-2 border-b">
        <div className="flex flex-row w-full gap-3 items-center mb-6">
          <FormRow
            type="date"
            dateOptions={{ type: "Month" }}
            value={startDate}
            label="Start auswählen"
            onChange={(val) => setStartDate(val)}
          />
          <FormRow
            type="date"
            dateOptions={{ type: "Month" }}
            value={endDate}
            label="Ende auswählen"
            onChange={(val) => setEndDate(val)}
          />
          <Tooltip title="Aktualisieren">
            <button
              onClick={handleSubmit}
              className="flex items-center justify-center w-10 h-10 rounded-full bg-brand hover:bg-brand-hover transition duration-150 min-w-[50px] mt-3"
            >
              {!isFetching ? (
                <RotateCw className="text-white" />
              ) : (
                <RotateCw className="text-white animate-spin" />
              )}
            </button>
          </Tooltip>
        </div>
      </div>

      {isLoading && (
        <div className="flex justify-center items-center w-full h-full">
          <CircularProgress />
        </div>
      )}
      {chartData && <ServiceSummaryChart chartData={chartData} />}
    </div>
  );
}
