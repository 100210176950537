import dayjs from "dayjs";
import { IProductDTO } from "../../Contexts/ProductContext/ProductProvider";
import {
  BillingPositionOrginType,
  DiscountType,
  IBillingPositionDTO,
} from "../../Interfaces/Bill";
import { IServiceDTO } from "../../Interfaces/Service";
import { ITravel } from "../../Interfaces/Travel";
import { roundNumberToDecimalPlaces } from "../../utils/MathUtils";

export function convertProductToBillingPosition(
  product: IProductDTO
): IBillingPositionDTO {
  let billingPosition: IBillingPositionDTO = {
    id: 0,
    originId: product.id,
    originType: BillingPositionOrginType.Product,
    billId: 0,
    brutto: calcProductBrutto(product),
    description: product.description,
    title: product.title,
    tax: product.ust ?? 0,
    quantity: product.count ?? 1,
    netto: product.salesPrice ?? 0,
    unitId: 0,
    discount: 0,
    hasDiscount: false,
    discountType: DiscountType.Percentage
  };

  return billingPosition;
}

export function convertServiceToBillingPosition(
  service: IServiceDTO,
  organizationTax: number
): IBillingPositionDTO {
  const baseBillingPosition: IBillingPositionDTO = createBaseBillingPosition(
    service,
    organizationTax
  );

  // If the service has no products or travels, return the base position
  if (!hasSubpositions(service)) {
    return baseBillingPosition;
  }

  // Create a summary billing position with subpositions
  const summaryPosition = createSummaryPosition(baseBillingPosition, service, organizationTax);

  // Recalculate totals for the summary position
  summaryPosition.netto = calcNettoTotal(summaryPosition);
  summaryPosition.brutto = calcTotal(summaryPosition);

  return summaryPosition;
}

function createBaseBillingPosition(
  service: IServiceDTO,
  organizationTax: number
): IBillingPositionDTO {
  return {
    id: 0,
    originId: service.id,
    originType: BillingPositionOrginType.Service,
    billId: 0,
    brutto: calcServiceBrutto(service, organizationTax),
    description: service.description,
    title: service.title,
    unitId: 1,
    from: service.employeeDatas[0].from,
    to: service.employeeDatas[0].to,
    quantity: getServiceDuration(service),
    netto: service.employeeDatas[0].hourlyRate, // TODO: Change netto calc
    tax: organizationTax, // TODO: Change tax
    discount: 0,
    hasDiscount: false,
    discountType: DiscountType.Percentage
  };
}

function hasSubpositions(service: IServiceDTO): boolean {
  return ((service.products && service.products.length > 0) ||
    (service.travels && service.travels.length > 0)) as boolean;
}

function createSummaryPosition(
  baseBillingPosition: IBillingPositionDTO,
  service: IServiceDTO,
  organizationTax: number
): IBillingPositionDTO {
  const summaryPosition = {
    ...baseBillingPosition,
    title: "Gesamt " + baseBillingPosition.title,
    subPositions: [] as IBillingPositionDTO[],
    isSummaryPosition: true,
    unitId: 0,
    quantity: 1,
  };

  // Add the service itself as a subposition
  summaryPosition.subPositions.push({
    ...baseBillingPosition,
    subPositions: [],
  });

  // Add products as subpositions
  if (service.products) {
    service.products.forEach((product: IProductDTO) => {
      summaryPosition.subPositions.push(
        convertProductToBillingPosition(product)
      );
    });
  }

  // Add travels as subpositions
  if (service.travels) {
    service.travels.forEach((travel: ITravel) => {
      summaryPosition.subPositions.push(convertTravelToBillingPosition(travel, organizationTax));
    });
  }

  return summaryPosition;
}

export function convertTravelToBillingPosition(
  travel: ITravel,
  organizationTax: number
): IBillingPositionDTO {
  const travelDistance = travel.return ? 2 * travel.distance : travel.distance;
  const travelTitle = travel.return
    ? `Fahrt ${travel.title} mit Rückfahrt`
    : `Fahrt ${travel.title}`;

  let billingPosition: IBillingPositionDTO = {
    id: 0,
    originId: travel.id,
    originType: BillingPositionOrginType.Travel,
    billId: 0,
    brutto: calcTravelBrutto(travel, organizationTax),
    description: travel.note,
    title: travelTitle,
    unitId: 8,
    quantity: travelDistance,
    netto: 0.5,
    tax: organizationTax,
    discount: 0,
    hasDiscount: false,
    discountType: DiscountType.Percentage
  };

  return billingPosition;
}

function calcProductBrutto(product: IProductDTO): number {
  return (product.salesPrice ?? 0) * (1 + (product.ust ?? 0) / 100);
}

function getServiceDuration(service: IServiceDTO): number {
  let serviceStart = dayjs(service.employeeDatas[0].from);
  let serviceEnd = dayjs(service.employeeDatas[0].to);

  let duration = serviceEnd.diff(serviceStart, "hour", true);
  duration = roundNumberToDecimalPlaces(duration, 2);

  return duration;
}

function calcServiceBrutto(
  service: IServiceDTO,
  organizationTax: number
): number {
  let duration = getServiceDuration(service);
  return (1 +( organizationTax / 100)) * (service.employeeDatas[0].hourlyRate ?? 0);
}

function calcTravelBrutto(travel: ITravel, organizationTax: number): number {
  const travelNetto = calcTravelNetto(travel);

  return travelNetto * (1 + (organizationTax / 100)); 
}

function calcTravelNetto(travel: ITravel): number { 

  const pricePerKm = 0.5; //TODO: change this, config/settings?

  return pricePerKm;
}

function calcNettoTotal(billingPosition: IBillingPositionDTO): number {
  let nettoTotal = 0;

  if (billingPosition.subPositions && billingPosition.subPositions.length > 0) {
    billingPosition.subPositions.forEach((subPosition: IBillingPositionDTO) => {
      nettoTotal += (subPosition.netto ?? 0) * (subPosition.quantity ?? 1);
    });
  }

  return nettoTotal;
}

function calcTotal(billingPosition: IBillingPositionDTO): number {
  let bruttoTotal = 0;

  if (billingPosition.subPositions && billingPosition.subPositions.length > 0) {
    billingPosition.subPositions.forEach((subPosition: IBillingPositionDTO) => {
      bruttoTotal += (subPosition.brutto ?? 0) * (subPosition.quantity ?? 1);
    });
  }

  return bruttoTotal;
}

export function getSummaryPositionNettoTotal(
  isSummaryPosition: boolean | undefined,
  subpositions: IBillingPositionDTO[] | undefined
) {
  if (!isSummaryPosition || !subpositions || subpositions.length === 0) return;

  let nettoTotal = 0;

  subpositions.forEach((subPosition: IBillingPositionDTO) => {
    nettoTotal += (subPosition.netto ?? 0) * (subPosition.quantity ?? 1);
  });

  return nettoTotal;
}

export function getSummaryPositionBruttoTotal(
  isSummaryPosition: boolean | undefined,
  subpositions: IBillingPositionDTO[] | undefined
) {
  if (!isSummaryPosition || !subpositions || subpositions.length === 0) return;

  let bruttoTotal = 0;

  subpositions.forEach((subPosition: IBillingPositionDTO) => {
    bruttoTotal += (subPosition.brutto ?? 0) * (subPosition.quantity ?? 1);
  });

  return bruttoTotal;
}
