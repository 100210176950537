import dayjs from 'dayjs'
import { createContext, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { IServiceDTO } from '../../Interfaces/Service'
import { ServiceContext } from '../../Interfaces/ServiceContext'
import { useUser } from '../UserContext/UserContext'
import ServiceReducer from './ServiceReducer'
import { useUserStaffDto } from '../../Hooks/useUserStaffDto'

const serviceContext = createContext<ServiceContext>({
})

//TODO CRUD + Kommunikation mit der API -> Nico oder Bernhard + Default-Value für Service (siehe TaskContext) erstellen -> Sollte Rücksicht auf den aktuellen User nehmen

function ServiceProvider({ children }: { children: ReactNode }) {

    //* Verbindung zur API
    const connection = useAuthConnection()
    const { user } = useUser();
    const userStaffDto = useUserStaffDto();

    function addHours(date: Date, hours: number): Date {
        date.setHours(date.getHours() + hours);

        return date;
    }

    const defaultService: IServiceDTO = useMemo(() => {
        return {
            id: 0,
            title: "",
            description: "",
            type: 1,
            billable: true,
            billed: false,
            intern: false,
            organizationId: user?.organizationId!,
            clientOrganizationId: -1,
            employeeDatas: [
                {
                    id: 0,
                    userId: user?.userId!,
                    from: dayjs().subtract(1, 'hour'),
                    to: dayjs(),
                    hourlyRate: userStaffDto?.employmentRelation?.hourlyRate ?? 80,
                    serviceId: 0,
                    organizationId: user?.organizationId!
                }
            ]
        }
    }, [userStaffDto])

    const [state, dispatch] = useReducer(ServiceReducer, {
        services: [],
        currentMonth: 0
    })

    const fetchTaskServiceCategories = async () => {
        const { data } = await connection.get("/taskServiceCategory")

        dispatch({
            type: "SET_TASK_SERVICE_CATEGORIES",
            payload: data
        })
    }

    const setServicesBilled = (ids: Array<number>) => {
        let tempServices = [...state.services];
        tempServices = tempServices.map((element: IServiceDTO) => {
            if (ids.includes(element.id)) {
                return { ...element, billed: true }
            }
            return element;
        })
        dispatch({
            type: "SET_SERVICES",
            payload: tempServices
        })
    }

    useEffect(() => {
        fetchTaskServiceCategories();
    }, [])

    return (
        <serviceContext.Provider
            value={{
                
                defaultService,
                setServicesBilled,
              
            }}
        >
            {children}
        </serviceContext.Provider>
    )
}

export default ServiceProvider


export const useServices = () => useContext(serviceContext)