import { StaffDTO, useStaff } from "../Contexts/StaffContext/StaffProvider";
import { useUser } from "../Contexts/UserContext/UserContext"

export const useUserStaffDto = () => {
    const {user} = useUser();
    const {staff} = useStaff();

    const userStaffDto = staff?.find((staff: StaffDTO) => staff.username === user?.username);
    console.log("user staff dto", userStaffDto);

    return userStaffDto;
}