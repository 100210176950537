import { Switch } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { IBillingPositionDTO } from "../../Interfaces/Bill";
import Android12Switch from "../Buttons/Switch/Android12Switch";
import InputFieldRefactored from "../InputField/InputFieldRefactored";

type BillingPositionDiscountFormRowProps = {
  billingPosition: IBillingPositionDTO;
  setBillingPosition: Dispatch<SetStateAction<IBillingPositionDTO>>;
};
export default function BillingPositionDiscountFormRow({
  billingPosition,
  setBillingPosition,
}: BillingPositionDiscountFormRowProps) {
  return (
    <>
      <div className="w-full flex justify-between items-center">
        <span className="text-sm">Rabatt</span>
        <span>
          <Android12Switch
            color="secondary"
            checked={
              billingPosition.hasDiscount === undefined
                ? false
                : billingPosition.hasDiscount
            }
            onChange={(e, val) =>
              setBillingPosition((oldVal: IBillingPositionDTO) => ({
                ...oldVal,
                hasDiscount: val,
              }))
            }
          />
        </span>
      </div>
      {billingPosition?.hasDiscount === true && (
        <>
          <div className="w-full flex justify-between items-center">
            <span className="text-sm">Prozentual</span>
            <Switch
              color="secondary"
              checked={billingPosition?.discountType}
              onChange={(e, val) =>
                setBillingPosition((oldVal: IBillingPositionDTO) => ({
                  ...oldVal,
                  discountType: !oldVal.discountType,
                }))
              }
            />
            <span className="text-sm">Betrag</span>
          </div>
          <div>
            <span className="text-[12px]">Rabatt:</span>
            <InputFieldRefactored
              type="number"
              step=".1"
              value={billingPosition.discount}
              onChange={(discount) =>
                setBillingPosition((old) => ({
                  ...old,
                  discount: parseFloat(discount),
                }))
              }
              placeholder={
                !billingPosition.discountType ? "Rabatt in %" : "Rabatt in €"
              }
            />
          </div>
        </>
      )}
    </>
  );
}
