import { baseAuthQuery } from "../baseAuthQuery";
import { IProjectDTO } from "../../Contexts/TaskContext/TaskContext";
import { createApi } from "@reduxjs/toolkit/query/react";

export interface ServiceMonthlySummary {
    month: string
    year: number
    total: number
    totalBilled: number
    totalNotBilled: number
}

export interface EmployeeDataSummary {
    name: string
    userId: number
    hours: number
    employeeHourlyRate: number
    fill: string
}

export const ProjectApi = createApi({
    reducerPath: "ProjectApi",
    baseQuery: baseAuthQuery,
    tagTypes: ["Project"],
    endpoints: (builder) => ({
        createProject: builder.mutation({
            query: (data: IProjectDTO) => ({
                url: "/project",
                method: "POST",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Project", id: "LIST" }],
        }),
        getAllProjects: builder.query<IProjectDTO[], void>({
            query: () => ({
                url: "/project",
            }),
            providesTags: (result, error, arg) => result ? [...result.map(({ id }) => ({ type: "Project" as const, id })), {type: "Project", id: "LIST"}] : []
        }),
        getProject: builder.query<IProjectDTO, number>({
            query: (projectId) => ({ url: `/project/${projectId}` }),
            providesTags: (result, error, arg) => [{ type: "Project", id: arg }]
        }),
        getServiceSummaryForProjectGroupedByMonth: builder.query<ServiceMonthlySummary[], any>({
            query: ({ projectId, startMonth, startYear, endMonth, endYear }) => ({
                url: `/project/serviceSummaryMonthly?projectId=${projectId}&startMonth=${startMonth}&startYear=${startYear}&endMonth=${endMonth}&endYear=${endYear}`,
                method: "GET"
            })
        }),
        getEmployeeDataSummaryForProject: builder.query<EmployeeDataSummary[], number>({
            query: (projectId) => ({
                url: `/project/employeeDataSummary?projectId=${projectId}`,
                method: "GET"
            })
        }),
        editProject: builder.mutation({
            query: (data: IProjectDTO) => ({
                url: "/project",
                method: "PUT",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Project", id: arg.id }],
        }),
        deleteProject: builder.mutation({
            query: (data: IProjectDTO) => ({
                url: "/project",
                method: "DELETE",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Project", id: arg.id }]
        }),
    })
})

export const {
    useCreateProjectMutation,
    useGetAllProjectsQuery,
    useGetProjectQuery,
    useGetServiceSummaryForProjectGroupedByMonthQuery,
    useGetEmployeeDataSummaryForProjectQuery,
    useEditProjectMutation,
    useDeleteProjectMutation
} = ProjectApi