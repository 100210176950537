import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { card } from "../../Interfaces/Card";

/* Icons */

import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import {
  IIntervall,
  IIntervallDTO,
  useIntervalls
} from "../../Contexts/IntervallContext/IntervallProvider";
import Card from "../Card/Card";
import Filter, { IFilterOption } from "../Filter/Filter";
import { IntervallFormModes } from "../Forms/EditCreateIntervall";
import IntervallRow from "./IntervallRow";

interface IIntervallCard extends card {
  setClickedIntervall?: Dispatch<SetStateAction<IIntervallDTO>>;
  setFormMode?: Dispatch<SetStateAction<IntervallFormModes>>;
}

function IntervallCard({
  width,
  height,
  order,
  setClickedIntervall,
  setFormMode,
}: IIntervallCard) {
  const { intervalls, fetchIntervalls } = useIntervalls();
  const { persons } = useContacts();
  const { companies } = useContacts();
  const [incomeIntervalls, setIncomeIntervalls] = useState<IIntervallDTO[]>([]);

  const incomeIntervallsOriginal = useMemo(
    () => getIncomeIntervalls(intervalls),
    [intervalls]
  );

  function getIncomeIntervalls(intervalls: IIntervall[]) {
    return intervalls.filter((intervall) => {
      if (!intervall || !intervall.subscriptionPositions?.length) return false;
  
      const tempSum = intervall.subscriptionPositions.reduce((sum, subscriptionPosition) => {
        if (!subscriptionPosition) return sum; // Ensure the item is valid
        const netto = subscriptionPosition.netto ?? 0;
        const quantity = subscriptionPosition.quantity ?? 0;
        const discount = subscriptionPosition.discount ?? 0;
        const discountType = subscriptionPosition.discountType ?? false;
        const hasDiscount = subscriptionPosition.hasDiscount ?? false;
  
        const discountAmount = hasDiscount
          ? discountType === false
            ? netto * quantity * (discount / 100)
            : discount
          : 0;
  
        return sum + (netto * quantity - discountAmount);
      }, 0);
  
      return tempSum >= 0;
    });
  }
  

  useEffect(() => {
    fetchIntervalls();
  }, []);

  useEffect(() => {
    console.log(intervalls);
    setIncomeIntervalls(getIncomeIntervalls(intervalls));
  }, [intervalls]);

  const filterOptions: IFilterOption[] = [
    { title: "Titel", valueKey: "title" },
    { title: "Beschreibung", valueKey: "description" },
    {
      title: "Organisation",
      valueKey: "customerOrganizationId",
      referenceList: companies,
      referenceListValueKey: "name",
    },
    {
      title: "Person",
      valueKey: "customerPersonId",
      referenceList: persons,
      referenceListValueKey: "completeName",
    },
  ];

  return (
    <Card
      title="Intervallleistungen"
      style={{ width, height, order }}
      fullHeightBody={true}
      right={() => (
        <Filter<IIntervallDTO>
          dataToFilter={incomeIntervalls!}
          setDataToFilter={setIncomeIntervalls}
          filterOptions={filterOptions}
          originalData={incomeIntervallsOriginal}
        />
      )}
    >
      {incomeIntervalls &&
        incomeIntervalls.map((intervall: any) => {
          return (
            <IntervallRow
              key={intervall.id}
              intervall={intervall}
              setFormMode={setFormMode}
              setClickedIntervall={setClickedIntervall}
            />
          );
        })}
    </Card>
  );
}

export default IntervallCard;
