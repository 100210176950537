import React from "react";
import { IBillingPositionDTO } from "../../Interfaces/Bill";
import { useGetAllTravelsQuery } from "../../redux/apiSlices/Travel";

export const useHandleTravelBillingDetailsChange = (
  billingPosition: IBillingPositionDTO,
  setBillingPosition: React.Dispatch<React.SetStateAction<IBillingPositionDTO>>,
  setCostPerKm: React.Dispatch<React.SetStateAction<number | undefined>>
) => {
  const { data: travels } = useGetAllTravelsQuery();
  const handleCostPerKmChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let costPerKm =
      event.target.value === "" ? undefined : Number(event.target.value);

    setCostPerKm(costPerKm);
    if (!costPerKm || costPerKm < 0) {
      return;
    }

    const travel = travels?.find((t) => t.id === billingPosition.originId);

    if (!travel) return;

    const netto = costPerKm;
    const tax = billingPosition.tax ?? 0;
    const brutto = parseFloat((netto * (1 + tax / 100)).toFixed(2));

    setBillingPosition((old) => ({
      ...old,
      netto: netto,
      brutto: brutto,
    }));
  };

  return {
    handleCostPerKmChange,
  };
};
