import { IBillingPositionDTO, useDefaultBillingPosition } from "../../Interfaces/Bill";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "../UI/Dialog";
import BillingPositionForm from "./BillingPositionForm";
import SummaryPositionForm from "./SummaryPositionForm";

type BillingPositionFormDialogProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  billId: number;
  initialBillingPosition?: IBillingPositionDTO;
  isOffer: boolean;
};

export default function BillingPositionFormDialog({
  billId,
  initialBillingPosition,
  isOpen,
  setIsOpen,
  isOffer,
}: BillingPositionFormDialogProps) {
  const isEditMode = !!initialBillingPosition;
  const defaultBillingPosition = useDefaultBillingPosition();

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{getDialogTitle(isEditMode, isOffer)}</DialogTitle>
        </DialogHeader>
        {initialBillingPosition?.isSummaryPosition ? (
          <SummaryPositionForm
            billId={billId}
            initialBillingPosition={initialBillingPosition}
            setDialogOpen={setIsOpen}
          />
        ) : (
          <BillingPositionForm
            isEditMode={isEditMode}
            billId={billId}
            initialBillingPosition={initialBillingPosition ? initialBillingPosition : defaultBillingPosition}
            setDialogOpen={setIsOpen}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

function getDialogTitle(isEditMode: boolean, isOffer: boolean): string {
  if (!isOffer && !isEditMode) return "Rechnungsposition erstellen";
  if (!isOffer && isEditMode) return "Rechnungsposition bearbeiten";
  if (isOffer && !isEditMode) return "Angebotposition erstellen";
  if (isOffer && isEditMode) return "Angebotposition bearbeiten";

  return "Rechnungsposition erstellen";
}
