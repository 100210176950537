import { AddRounded } from "@mui/icons-material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import {
  defaultIntervall,
  IIntervallDTO,
  useIntervalls,
} from "../../Contexts/IntervallContext/IntervallProvider";
import { useProducts } from "../../Contexts/ProductContext/ProductProvider";
import Card from "../Card/Card";
import EditCreateIntervallForm, {
  IntervallFormModes,
} from "../Forms/EditCreateIntervall";

export interface props {
  height?: string;
  width?: string;
  order?: string;
  mode?: "create" | "edit";
  clickedIntervall?: IIntervallDTO;
  setClickedIntervall?: Dispatch<SetStateAction<IIntervallDTO>>;
  formMode?: IntervallFormModes;
  setFormMode?: Dispatch<SetStateAction<IntervallFormModes>>;
}

export const validateIntervallForm = (
  intervall: IIntervallDTO,
  setError: Dispatch<SetStateAction<any>>
) => {
  let valid = true;

  console.log("intervall to validate", intervall);

  if (
    intervall.customerOrganizationId === undefined &&
    intervall.customerPersonId === undefined
  ) {
    setError((old: any) => ({ ...old, customerError: true }));
    valid = false;
  } else {
    setError((old: any) => ({ ...old, customerError: false }));
  }

  if (intervall.prefix === undefined || isNaN(intervall.prefix)) {
    setError((old: any) => ({ ...old, prefixError: true }));
    valid = false;
  } else {
    setError((old: any) => ({ ...old, prefixError: false }));
  }

  if ((intervall.title.length ?? 0) < 1) {
    setError((old: any) => ({ ...old, titleError: true }));
    valid = false;
  } else {
    setError((old: any) => ({ ...old, titleError: false }));
  }

  // Create a Date object for today's date
  const today = new Date();
  // Add one day to today's date
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  if (intervall.begin === undefined /*|| intervall.begin > tomorrow*/) {
    setError((old: any) => ({ ...old, beginError: true }));
    valid = false;
  } else {
    setError((old: any) => ({ ...old, beginError: false }));
  }

  if (
    intervall.period === undefined ||
    intervall.period < 1 ||
    isNaN(intervall.period)
  ) {
    setError((old: any) => ({ ...old, periodError: true }));
    valid = false;
  } else {
    setError((old: any) => ({ ...old, periodError: false }));
  }

  return valid;
};

export default function IntervallDataCard({
  width,
  height,
  order,
  mode = "create",
  clickedIntervall,
  setClickedIntervall,
  formMode,
  setFormMode,
}: props) {
  const [intervall, setIntervall] = useState<IIntervallDTO>(defaultIntervall);

  const { fetchProducts } = useProducts();
  const { fetchCompanies } = useContacts();
  const { addIntervall, updateIntervall } = useIntervalls();

  const [chosenPeriod, setChosenPeriod] = useState<1 | 2 | 3>(2);

  useEffect(() => {
    fetchProducts();
    fetchCompanies!();
  }, []);

  const handleSubmit = () => {
    try {
      if (mode === "create") {
        if (validateIntervallForm(intervall, setError)) {
          addIntervall!(intervall);
        }
      } else {
        if (
          clickedIntervall &&
          validateIntervallForm(clickedIntervall, setError)
        ) {
          updateIntervall!(clickedIntervall);
        }
      }
    } catch (exception) {
      console.error(exception);
    } finally {
      setIntervall({
        ...defaultIntervall,
        period: 1,
        periodIsDays: false,
        periodIsMonths: true,
        periodIsYears: false,
        inAdvance: true,
      });
      setChosenPeriod(2);
    }
  };

  const [error, setError] = useState<any>({});

  return (
    <Card
      title={
        formMode === "editIntervall"
          ? "Intervall bearbeiten"
          : "Intervall erstellen"
      }
      style={{ width, height, order }}
      fullHeightBody={true}
      right={() => <div></div>}
      cta={true}
      ctaTitle={
        formMode === "editIntervall" ? "Speichern" : "Intervall erstellen"
      }
      buttonIcon={<AddRounded fontSize="small" />}
      ctaFunction={() => handleSubmit()}
    >
      {formMode === "editIntervall" ? (
        <EditCreateIntervallForm
          intervall={clickedIntervall!}
          setIntervall={setClickedIntervall!}
          error={error}
          setError={setError}
          mode="edit"
          setFormMode={setFormMode}
        />
      ) : (
        <EditCreateIntervallForm
          intervall={intervall}
          setIntervall={setIntervall}
          error={error}
          setError={setError}
          mode="createNewIntervall"
          setFormMode={setFormMode}
        />
      )}
    </Card>
  );
}
