import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { IServiceDTO, serviceWithNames } from "../../Interfaces/Service";
import styles from "./Service.module.css";

//Icons
import { KeyboardArrowUpRounded, MoreVertRounded } from "@mui/icons-material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Collapse } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useModals } from "../../Contexts/DialogContext/DialogProvider";
import { useServices } from "../../Contexts/ServiceContext/ServiceContext";
import { IStaff, useStaff } from "../../Contexts/StaffContext/StaffProvider";
import { useSnackBar } from "../../Hooks/useSnackBar";
import { serviceFormModes } from "../../Pages/Services/Services";
import IconTooltipButton from "../Buttons/IconTooltipButton/IconTooltipButton";
import ContextMenu from "../Menu/ContextMenu";
import { useHandleDeleteService } from "../../redux/requestHandlers/Service";

interface ServiceProps {
  service: serviceWithNames;
  backgroundColor?: string;
  setClickedService?: Dispatch<SetStateAction<IServiceDTO>>;
  setFormMode?: Dispatch<SetStateAction<serviceFormModes>>;
  isAdminView?: boolean;
}

const Service = forwardRef<HTMLDivElement, ServiceProps>(
  (
    {
      service,
      backgroundColor,
      setClickedService,
      setFormMode,
      isAdminView = false,
    },
    ref
  ) => {
    const [expanded, setExpanded] = useState(false);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { changeVisibility } = useModals();
    const { handleDeleteService, isLoading: isDeleteServiceLoading } =
      useHandleDeleteService();

    const { enqueueSnackbar } = useSnackBar();

    const { defaultService } = useServices();

    const { staff } = useStaff();

    const navigate = useNavigate();

    const handleDelete = async () => {
      changeVisibility!("yesNoDialog", true, {
        props: {
          object: {
            submitFunction: () => {
              handleDeleteService(service);
            },
            modalTitle: "Leistung löschen",
            modalText: "Möchten Sie diese Leistung wirklich löschen?",
            confirmButtonText: "Löschen",
          },
        },
      });
    };

    useEffect(() => {
      if (expanded && setClickedService && setFormMode) {
        setClickedService({ ...service });
        setFormMode("editService");
      }

      if (!expanded && setClickedService && setFormMode) {
        setClickedService(defaultService!);
        setFormMode("createService");
      }
    }, [expanded]);

    return (
      <div
        ref={ref}
        className={"flex column ".concat(styles.container)}
        style={{ backgroundColor: backgroundColor ?? "transparent" }}
      >
        <header className="flex centered-aligned stretched-justify pointer">
          <div
            className="flex centered-aligned"
            style={{ gap: "6px", width: "100%" }}
            onClick={() => setExpanded((val) => !val)}
          >
            <div className={styles.accent} />
            <span className="bold-big">{service.title}</span>
          </div>
          <div className="flex centered-aligned">
            <IconTooltipButton
              style={{ marginRight: "-5px" }}
              onClick={() => setExpanded((val) => !val)}
            >
              {!expanded && <KeyboardArrowDownRoundedIcon fontSize="small" />}
              {expanded && <KeyboardArrowUpRounded fontSize="small" />}
            </IconTooltipButton>
            <IconTooltipButton
              title="Optionen"
              arrow
              style={{ marginLeft: "-5px" }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertRounded fontSize="small" />
            </IconTooltipButton>
            <ContextMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              options={[
                /* TODO {
                                    label: "Verrechnen",
                                    onClick: () => null,
                                    selectable: false
                                },*/
                {
                  label: "Bearbeiten",
                  onClick: () =>
                    changeVisibility!("editCreateService", true, {
                      props: { object: service, mode: "edit" },
                    }),
                  selectable: false,
                },
                {
                  label: "Löschen",
                  onClick: () => handleDelete(),
                  selectable: false,
                },
              ]}
            />
          </div>
        </header>
        <section
          className="label flex centered-aligned"
          style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}
        >
          <span style={{ textOverflow: "ellipsis" }}>
            {
              service.clientOrganizationName /* Hier sollte die zur ID gehrenden Firma stehen TODO: ergänzen, wenn der Firmenstate existiert */
            }
          </span>
          <div
            style={{
              height: "12px",
              width: "1.5px",
              backgroundColor: "black",
              borderRadius: "100px",
            }}
          />

          {service.employeeDatas[0].to ? (
            <span>
              {dayjs(service.employeeDatas[0]?.to).format("DD.MM.YYYY")}
            </span>
          ) : (
            <span className="font-bold text-green-700">Aktiv</span>
          )}
        </section>
        {isAdminView && (
          <section
            className="label flex centered-aligned"
            style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}
          >
            <span style={{ textOverflow: "ellipsis", color: "var(--blue)" }}>
              @
              {
                staff.find(
                  (employee: IStaff) =>
                    employee.id === service.employeeDatas[0].userId
                )?.username
              }
            </span>
          </section>
        )}
        <Collapse in={expanded}>
          <section style={{ padding: "10px", fontSize: "14px" }}>
            {service.description}
          </section>
        </Collapse>
      </div>
    );
  }
);

export default Service;
