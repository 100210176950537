import { Delete } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { IDocument } from "../../Contexts/DocumentContext/DocumentContext";
import {
  IEmployee,
  useEmployees,
} from "../../Contexts/EmployeeContext/EmployeeProvider";
import {
  IProductDTO,
  useProducts,
} from "../../Contexts/ProductContext/ProductProvider";
import { useTasks } from "../../Contexts/TaskContext/TaskContext";
import { useUser } from "../../Contexts/UserContext/UserContext";
import { ITaskDTO } from "../../Interfaces/Task";
import { taskFormModes } from "../../Pages/Aufgaben/Aufgaben";
import { useGetAllProjectsQuery } from "../../redux/apiSlices/Project";
import { useGetTaskServiceCategoriesQuery } from "../../redux/apiSlices/Service";
import ResetFormButton from "../Buttons/ResetFormButton/ResetFormButton";
import InputField from "../InputField/InputField";
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored";
import AddProductFormRow from "../Product/AddProductFormRow";
import FormRow from "./FormUtils/FormRow";
import { StaffDTO, useStaff } from "../../Contexts/StaffContext/StaffProvider";
import StaffSelectRow from "../Staff/StaffSelectRow";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../UI/Collapsible";
import { ChevronDown, ChevronUp } from "lucide-react";
import FormSection from "./FormUtils/FormSection";

export interface form<T, M = void> {
  object: T;
  setObject: React.Dispatch<React.SetStateAction<T>>;
  mode?: string;
  setFormMode?: Dispatch<SetStateAction<M>>;
  submitFunction?: () => void;
  loading?: boolean;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  /*
        used to pass error variables to a form
        required when the submit method for the form is in a different parent component
    */
  errorVariables?: Record<string, boolean>;
}

const CreateTaskForm = ({
  object,
  setObject,
  mode = "createTask",
  setFormMode,
  errorVariables,
}: form<ITaskDTO, taskFormModes>) => {
  const { companies } = useContacts();

  const { staff } = useStaff();

  const { user } = useUser();

  const { defaultTask, getPicturesToTask, deleteDocumentToTask } = useTasks();
  const { data: projects } = useGetAllProjectsQuery();

  const { data: taskServiceCategories } = useGetTaskServiceCategoriesQuery();

  const { products } = useProducts();

  const handleReset = () => {
    setObject(defaultTask!);
    setFormMode!("createTask");
  };

  const [staffOptions, setStaffOptions] = useState(
    transformStaffOptions(staff)
  );
  useEffect(() => {
    setStaffOptions(transformStaffOptions(staff));
  }, [staff]);

  useEffect(() => {
    if (getPicturesToTask && object.id) {
      getPicturesToTask(object.id).then((res) => {
        setDocuments(res);
        console.log(res);
      });
    }
  }, [object]);

  const [documents, setDocuments] = useState<Array<IDocument>>([]);

  const [product, setProduct] = useState<IProductDTO>();
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);

  return (
    <Collapsible open={isCollapsibleOpen} onOpenChange={setIsCollapsibleOpen}>
      <form className="form">
        <div className="form-row-label">
          <span className="label">Autor:</span>
          <InputField
            placeholder="Autor"
            value={
              staff?.find(
                (employee: StaffDTO) => employee.id === object?.userId
              )?.username ??
              staff?.find((employee: StaffDTO) => employee?.id === user?.userId)
                ?.username
            }
            isReadOnly={true}
            onChangeHandler={() => {}}
          />
        </div>
        {/*       <FormRow
        type="select"
        label="Mitarbeiter*innen"
        placeholder="Mitarbeiter*innen auswählen"
        value={object?.userIds}
        onChange={(val) => setObject((prev) => ({ ...prev, userIds: val }))}
        selectOptions={{
          //Legt den Titel-Key des aktiven Objekts fest -> Wert wird in das Feld eingefügt.
          titleKey: "username",
          //Legt den Wert-Key des aktiven Objekts fest -> Wert wird demnach an den onChange-Handler übergeben.
          valueKey: "id",
          options: staffOptions,
          multiple: true,
        }}
        error={errorVariables?.employeeError}
        errorMessage={InputFieldErrorMessages.EmployeeRequired}
      /> */}
        <StaffSelectRow
          selectedStaffIds={object.userIds ?? []}
          onChange={(val) => setObject((prev) => ({ ...prev, userIds: val }))}
          error={errorVariables?.employeeError}
          errorMessage={InputFieldErrorMessages.EmployeeRequired}
        />
        {/*<FormRow
                value={object.intern}
                type="Switch"
                onChange={(val) => setObject((old) => ({ ...old, intern: val }))}
                title="Intern"
            />*/}
        <FormRow
          type="select"
          label="Kunde"
          placeholder="Kunde auswählen"
          value={object?.customerOrganizationId}
          onChange={(val: number) => {
            setObject((prev) => ({ ...prev, customerOrganizationId: val }));
          }}
          selectOptions={{
            //Legt den Titel-Key des aktiven Objekts fest -> Wert wird in das Feld eingefügt.
            titleKey: "name",
            //Legt den Wert-Key des aktiven Objekts fest -> Wert wird demnach an den onChange-Handler übergeben.
            valueKey: "id",
            options: companies,
          }}
          error={errorVariables?.customerError}
          errorMessage={InputFieldErrorMessages.CustomerRequired}
        />
        <FormRow
          value={object?.projectId}
          type="select"
          placeholder="Projekt"
          onChange={(val) => setObject((old) => ({ ...old, projectId: val }))}
          label="Projekt zuordnen (optional)"
          selectOptions={{
            options: projects ?? [],
            titleKey: "title",
            valueKey: "id",
          }}
        />
        <FormRow
          type="text"
          value={object?.title}
          onChange={(val) => setObject((prev) => ({ ...prev, title: val }))}
          label="Bezeichnung (Rechungsposition)"
          placeholder="Bezeichnung"
          maxLength={500}
          error={errorVariables?.titleError}
          errorMessage={InputFieldErrorMessages.TitleError}
        />
        <FormRow
          type="multiline"
          value={object?.description ?? ""}
          onChange={(val) =>
            setObject((prev) => ({ ...prev, description: val }))
          }
          label="Beschreibung"
          placeholder="Beschreibung (max. 1500 Zeichen)"
          maxLength={1500}
        />
        <CollapsibleTrigger asChild>
          <div className="w-full flex items-center justify-center hover:cursor-pointer">
            Weitere Optionen
            <IconButton>
              {!isCollapsibleOpen ? <ChevronDown /> : <ChevronUp />}
            </IconButton>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent className="flex flex-col gap-4">
          <FormSection title="Allgemein">
            <FormRow
              type="multiline"
              value={object?.note ?? ""}
              onChange={(val) => setObject((old) => ({ ...old, note: val }))}
              label="Notiz (optional)"
              placeholder="Notiz (max. 1500 Zeichen"
              maxLength={1500}
            />
            <FormRow
              type="select"
              label="Kategorie (optional)"
              value={object.categoryId}
              onChange={(val) =>
                setObject((old) => ({ ...old, categoryId: val }))
              }
              selectOptions={{
                options: taskServiceCategories ?? [],
                titleKey: "name",
                valueKey: "id",
              }}
            />
          </FormSection>

          <FormSection title="Produkte">
            {mode === "createTask" && (
              <FormRow
                type="select"
                label="Produkt als Vorlage (optional)"
                placeholder="Produkt auswählen"
                value={product?.id}
                onChange={(val: number) => {
                  let tempProduct = products.find((p) => p.id === val);
                  setProduct(tempProduct);
                  setObject((prev) => ({
                    ...prev,
                    title: tempProduct?.title,
                    description: tempProduct?.description,
                  }));
                }}
                selectOptions={{
                  titleKey: "title",
                  multiple: false,
                  valueKey: "id",
                  options: products,
                }}
              />
            )}
            <AddProductFormRow object={object} setObject={setObject} />
          </FormSection>
          <FormSection title="Planung">
            <FormRow
              value={
                mode === "editTask"
                  ? object?.creationDate
                  : object?.creationDate ?? dayjs()
              }
              type="date"
              dateOptions={{
                type: "Date",
              }}
              onChange={() => {}}
              readonly
              label="Erstellungsdatum"
            />
            <FormRow
              value={object?.scheduledStartDate}
              type="date"
              onChange={(val) =>
                setObject((old) => ({ ...old, scheduledStartDate: val }))
              }
              label="Geplanter Start (optional)"
            />
            <FormRow
              value={object?.scheduledDate}
              type="date"
              onChange={(val) =>
                setObject((old) => ({ ...old, scheduledDate: val }))
              }
              label="Geplante Fertigstellung (optional)"
            />
          </FormSection>
        </CollapsibleContent>

        <ResetFormButton
          title="Felder zurücksetzen"
          onClickHandler={() => handleReset()}
        />
        {documents && documents?.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2 style={{ width: "100%" }}>Aufgenommene Bilder:</h2>
            {documents?.map((doc: IDocument, index: number) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom:
                    index === (documents?.length ?? 0) - 1 ? "0em" : "1em",
                }}
              >
                <img
                  style={{ width: "calc(85%)", marginBottom: "0.25em" }}
                  key={index}
                  src={`data:${doc.fileEnding};base64,${doc.base64}`}
                  alt={`captured-${index}`}
                />
                {!(
                  user?.organizationRoleId === 20147 ||
                  user?.organizationRoleId === 20165
                ) && ( //EHParkett & MasterCares
                  <Button
                    startIcon={<Delete />}
                    onClick={() => {
                      console.log(doc);
                      if (deleteDocumentToTask) {
                        setDocuments((old: Array<IDocument>) =>
                          old.filter(
                            (tempDoc: IDocument) => doc.id !== tempDoc.id
                          )
                        );
                        deleteDocumentToTask(object.id!, doc.id!);
                      }
                    }}
                    color="error"
                    variant="contained"
                  >
                    Entfernen
                  </Button>
                )}
              </div>
            ))}
          </div>
        )}
      </form>
    </Collapsible>
  );
};

export default CreateTaskForm;

type EmployeeOptions = {
  id: number;
  username: string;
  isSelectable: boolean;
};

function transformEmployeeOptions(employees: IEmployee[]): EmployeeOptions[] {
  // Map employees to options
  const tempOptions: EmployeeOptions[] = employees.map((employee) => ({
    id: employee.id,
    username: employee.active
      ? employee.username
      : `${employee.username} (inaktiv)`,
    isSelectable: employee.active ?? true,
  }));

  // Custom comparator: active first, then sort alphabetically (ignoring the suffix)
  tempOptions.sort((a, b) => {
    if (a.isSelectable && !b.isSelectable) return -1;
    if (!a.isSelectable && b.isSelectable) return 1;

    // Remove " (inaktiv)" if present before comparing
    const nameA = a.username.replace(" (inaktiv)", "");
    const nameB = b.username.replace(" (inaktiv)", "");
    return nameA.localeCompare(nameB);
  });

  return tempOptions;
}

type StaffOptions = {
  id: number;
  username: string;
  isSelectable: boolean;
};
function transformStaffOptions(staff: StaffDTO[]) {
  // Map employees to options
  const tempOptions: StaffOptions[] = staff.map((employee) => ({
    id: employee.id,
    username: employee.active
      ? employee.username
      : employee.deleted
      ? `${employee.username} (gelöscht)`
      : `${employee.username} (inaktiv)`,
    isSelectable: (employee.active && !employee.deleted) ?? true,
  }));

  // Custom comparator: active first, then sort alphabetically (ignoring the suffix)
  tempOptions.sort((a, b) => {
    if (a.isSelectable && !b.isSelectable) return -1;
    if (!a.isSelectable && b.isSelectable) return 1;

    // Remove " (inaktiv)" if present before comparing
    const nameA = a.username.replace(" (inaktiv)", "");
    const nameB = b.username.replace(" (inaktiv)", "");
    return nameA.localeCompare(nameB);
  });

  return tempOptions;
}
