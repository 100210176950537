export default function FormSection({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <div className="relative flex flex-col w-full border rounded-xl border-gray-200 p-4">
      <div className="absolute -top-3 left-4 bg-white px-2 text-gray-700 font-semibold">
        {title}
      </div>
      {children}
    </div>
  );
}
