import { useSnackbar } from "notistack";
import { BillPrefix } from "../../Interfaces/Accounting";
import {
  useAddBillPrefixMutation,
  useDeleteBillPrefixMutation,
  useEditBillPrefixMutation,
  useGetBillPrefixesQuery,
} from "../apiSlices/BillPrefix";
import { RTKQueryErrorHandler } from "./utils";
import { useBills } from "../../Contexts/BillContext/BillProvider";

export const useHandleAddBillPrefix = () => {
  const [addBillPrefix, { isLoading, isError, error }] =
    useAddBillPrefixMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data: billPrefixes } = useGetBillPrefixesQuery();
  const { setBillPrefixes } = useBills();
  const handleAddBillPrefix = async (billPrefix: BillPrefix) => {
    let x = enqueueSnackbar("Rechnungskreis wird erstellt", {
      variant: "default",
    });

    try {
      const result = await addBillPrefix(billPrefix).unwrap();
      //Success
      if (billPrefixes && setBillPrefixes) {
        console.log("setting billPrefixes", billPrefixes);
        setBillPrefixes([...billPrefixes, result]);
      }
      closeSnackbar(x);
      enqueueSnackbar("Rechnungskreis erfolgreich erstellt", {
        variant: "success",
      });
    } catch (err) {
      closeSnackbar(x);
      const errorMessage = RTKQueryErrorHandler(err);
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return { handleAddBillPrefix, isLoading, isError, error };
};

export const useHandleDeleteBillPrefix = () => {
  const [deleteBillPrefix, { isLoading, isError, error }] =
    useDeleteBillPrefixMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data: billPrefixes } = useGetBillPrefixesQuery();
  const { setBillPrefixes } = useBills();

  const handleDeleteBillPrefix = async (billPrefix: BillPrefix) => {
    let x = enqueueSnackbar("Rechungskreis wird entfernt", {
      variant: "default",
    });

    try {
      const result = await deleteBillPrefix(billPrefix).unwrap();
      //Success
      if (billPrefixes && setBillPrefixes) {

        setBillPrefixes(billPrefixes.filter((bp) => bp.id !== billPrefix.id));
      }
      closeSnackbar(x);
      enqueueSnackbar("Rechnungskreis erfolgreich entfernt", {
        variant: "success",
      });
    } catch (err) {
      closeSnackbar(x);
      console.log(err);
      const errorMessage = RTKQueryErrorHandler(err);
      enqueueSnackbar(errorMessage, {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  };

  return { handleDeleteBillPrefix, isLoading, isError, error };
};

export const useHandleEditBillPrefix = () => {
  const [editBillPrefix, { isLoading, isError, error, isSuccess }] =
    useEditBillPrefixMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data: billPrefixes } = useGetBillPrefixesQuery();
  const { setBillPrefixes } = useBills();

  const handleEditBillPrefix = async (billPrefix: BillPrefix) => {
    let x = enqueueSnackbar("Rechnungskreis wird bearbeitet", {
      variant: "default",
    });

    try {
      const result = await editBillPrefix(billPrefix).unwrap();

      //Success
      closeSnackbar(x);
      enqueueSnackbar("Rechungskreis erfolgreich bearbeitet", {
        variant: "success",
      });
      if (billPrefixes && setBillPrefixes) {
        setBillPrefixes(billPrefixes.map((bp) => (bp.id === result.id ? result : bp)));
      }
    } catch (err) {
      closeSnackbar(x);
      const errorMessage = RTKQueryErrorHandler(err);
      enqueueSnackbar(errorMessage, {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  };

  return { handleEditBillPrefix, isLoading, isError, error, isSuccess };
};
