import { AddRounded } from "@mui/icons-material";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction, useState } from "react";
import {
  StaffDTO,
  defaultStaff,
  useStaff,
} from "../../Contexts/StaffContext/StaffProvider";
import Card from "../Card/Card";
import CreateEditPersonalForm from "./CreateEditPersonalForm";
import { card } from "../../Interfaces/Card";
import { userFormModes } from "../../Pages/Verwaltung/Personal/Personal";

export interface props {
  height?: string;
  width?: string;
  order?: string;
}

interface IPersonalDataCard extends card {
  clickedUser?: StaffDTO;
  setClickedUser?: Dispatch<SetStateAction<StaffDTO>>;
  formMode?: userFormModes;
  setFormMode?: Dispatch<SetStateAction<userFormModes>>;
}

export default function PersonalDataCard({
  width,
  height,
  order,
  clickedUser,
  setClickedUser,
  formMode,
  setFormMode,
}: IPersonalDataCard) {
  const [staff, setStaff] = useState<StaffDTO>(defaultStaff);

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const staffContext = useStaff();

  const [error, setError] = useState<any>({
    usernameError: false,
    organizationRoleError: false,
    eMailError: false,
    passwordError: false,
    passwordNotMatchError: false,
    usernameAlreadyExists: false,
  });

  function validateInputCreate() {
    setError((old: any) => ({ ...old, usernameAlreadyExists: false }));
    if (staff.username.length <= 4) {
      setError((old: any) => ({ ...old, usernameError: true }));
    } else {
      setError((old: any) => ({ ...old, usernameError: false }));
    }

    if (!staff.organizationRoleId || staff.organizationRoleId <= 0) {
      setError((old: any) => ({ ...old, organizationRoleError: true }));
    } else {
      setError((old: any) => ({ ...old, organizationRoleError: false }));
    }

    if (staff.eMail.length <= 6 || !staff.eMail.includes("@")) {
      setError((old: any) => ({ ...old, eMailError: true }));
    } else {
      setError((old: any) => ({ ...old, eMailError: false }));
    }

    if (password.length < 8) {
      setError((old: any) => ({ ...old, passwordError: true }));
    } else {
      setError((old: any) => ({ ...old, passwordError: false }));
    }

    if (password !== confirmPassword) {
      setError((old: any) => ({ ...old, passwordNotMatchError: true }));
    } else {
      setError((old: any) => ({ ...old, passwordNotMatchError: false }));
    }

    if (
      staff.username.length <= 4 ||
      !staff.organizationRoleId ||
      staff.organizationRoleId <= 0 ||
      staff.eMail.length <= 6 ||
      !staff.eMail.includes("@") ||
      password.length < 8 ||
      password !== confirmPassword
    ) {
      return false;
    }
    return true;
  }

  function validateInputEdit(staff: StaffDTO) {
    if (!staff.organizationRoleId || staff.organizationRoleId <= 0) {
      setError((old: any) => ({ ...old, organizationRoleError: true }));
    } else {
      setError((old: any) => ({ ...old, organizationRoleError: false }));
    }
    if (staff.eMail.length <= 6 || !staff.eMail.includes("@")) {
      setError((old: any) => ({ ...old, eMailError: true }));
    } else {
      setError((old: any) => ({ ...old, eMailError: false }));
    }

    if (
      !staff.organizationRoleId ||
      staff.organizationRoleId <= 0 ||
      staff.eMail.length <= 6 ||
      !staff.eMail.includes("@")
    ) {
      return false;
    }

    return true;
  }

  const handleSubmit = async () => {
    switch (formMode) {
      case "createUser":
        if (validateInputCreate() && staffContext.addStaff) {
          try {
            await staffContext.addStaff(
              { ...staff, password: password },
              setError
            );
            setStaff(defaultStaff);
            setPassword("");
            setConfirmPassword("");
          } catch (error) {
            console.error(error);
          }
        }
        return;
      case "editUser":
        if (
          validateInputEdit(clickedUser!) &&
          staffContext.updateStaff &&
          clickedUser
        ) {
          try {
            await staffContext.updateStaff({ ...clickedUser });
            setStaff(defaultStaff);
            setPassword("");
            setConfirmPassword("");
          } catch (error) {
            console.error(error);
          }
        }
        return;
    }
  };

  return (
    <Card
      title={
        formMode === "editUser"
          ? "Mitarbeiter bearbeiten"
          : "Mitarbeiter erstellen"
      }
      style={{ width, height, order }}
      fullHeightBody={true}
      right={() => <div></div>}
      cta={true}
      ctaTitle={formMode === "editUser" ? "Speichern" : "Mitarbeiter erstellen"}
      buttonIcon={<AddRounded fontSize="small" />}
      ctaFunction={
        async () =>
          handleSubmit() /*() => { productContext.addProduct(product); setProduct(defaultStaff); }*/
      }
    >
      {formMode === "editUser" ? (
        <CreateEditPersonalForm
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          password={password}
          setPassword={setPassword}
          setStaff={setClickedUser!}
          staff={clickedUser!}
          error={error}
          formMode={formMode}
        />
      ) : (
        <CreateEditPersonalForm
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          password={password}
          setPassword={setPassword}
          setStaff={setStaff}
          staff={staff}
          error={error}
        />
      )}
    </Card>
  );
}
