import { AxiosError, AxiosResponse } from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { autoHideDurationDefault } from "../../Global/Variables";
import useAuthConnection from "../../Hooks/useAuthConnection";
import { useSnackBar } from "../../Hooks/useSnackBar";
import {
  accounting,
  BillPrefix,
  BillPrefixDTO,
  IRegistrierkasse,
  IRegistrierkasseBelegCreationDTO,
  IRegistrierkasseDatenerfassungsprotokoll,
  IRegistrierkassenzertifikat,
} from "../../Interfaces/Accounting";
import {
  IBillDTO,
  IBillingPosition,
  IBillListDTO,
  IUnit,
} from "../../Interfaces/Bill";
import { IDeliveryNoteDTO } from "../../Pages/Services/LieferscheinErstellen";
import { useCompanyData } from "../CompanyDataContext/CompanyDataProvider";
import { useUser } from "../UserContext/UserContext";
import BillReducer from "./BillReducer";

export interface IPosition extends IBillingPosition {
  positions?: Array<IPosition>;
  userId?: number;
  customerOrganizationId?: number;
  customerPersonId?: number;
  tempId?: number;
}

interface IBillContext {
  bills: Array<IBillListDTO>;
  billPrefixes: Array<BillPrefixDTO>;
  units: Array<IUnit>;
  billables: Array<IPosition>;
  accounting: accounting | undefined;
  registrierkasse?: IRegistrierkasse;
  registrierkasseZertifikat?: IRegistrierkassenzertifikat;
  registrierkasseDatenerfassungsprotokoll?: Array<IRegistrierkasseDatenerfassungsprotokoll>;
  defaultBill?: IBillDTO;
  defaultOffer?: IBillDTO;
  updateBillingPosition:
    | ((billingposition: IBillingPosition) => void)
    | undefined;
  deleteBill?: (bill: IBillDTO) => void;
  addBill?: (bill: IBillListDTO) => void;
  addBillWithBillingPositions?: (
    bill: IBillDTO,
    selectedServices?: Array<number>,
    selectedPositions?: Array<IPosition>
  ) => Promise<any>;
  addBillFromDeliveryNote?: (
    deliveryNote: IDeliveryNoteDTO,
    billNumber: number,
    billPrefixId: number
  ) => Promise<any>;
  updateBill?: (bill: IBillDTO) => void;
  addReceipt?: (bill: IBillListDTO) => Promise<any>;
  updateReceipt?: (bill: IBillDTO) => void;
  createRegistrierkasseBeleg?: (
    beleg: IRegistrierkasseBelegCreationDTO
  ) => Promise<any>;
  addBillPrefix?: (billPrefix: BillPrefix) => void;
  setBillPrefixes?: (billPrefixes: BillPrefixDTO[]) => void;
  markBillCreated?: (bill: IBillDTO) => Promise<any>;
  markBillTasksGenerated?: (bill: IBillDTO) => Promise<any>;
  receiveBill?: (bill: IBillListDTO) => void;
  receiveUpdatedBill?: (bill: IBillListDTO) => void;
  receiveDeleteBill?: (bill: IBillListDTO) => void;
  setAccounting?: (accounting: accounting) => void;
  fetchBillablesAndReturn?: (
    customerOrganizationId?: number,
    rounding?: number
  ) => Promise<Array<IPosition>>;
}

const BillContext = createContext<IBillContext>({
  bills: [],
  billPrefixes: [],
  units: [],
  accounting: undefined,
  updateBillingPosition: undefined,
  billables: [],
});

const BillProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();

  const connection = useAuthConnection();

  const { enqueueSnackbar, closeSnackbar } = useSnackBar();

  const updateBillingPosition = (billingposition: IBillingPosition) => {
    dispatch({
      type: "UPDATE_BILLINGPOSITION",
      payload: billingposition,
    });
  };

  const fetchBills = () => {
    connection.get("/bill/dto").then((res: AxiosResponse) => {
      setBills(res.data);
    });
  };

  const setBills = (bills: any) => {
    dispatch({
      type: "SET_BILLS",
      payload: bills,
    });
  };

  const setAccounting = (accounting: any) => {
    dispatch({
      type: "SET_ACCOUNTING",
      payload: accounting,
    });
  };

  const deleteBill = (bill: any) => {
    dispatch({
      type: "DELETE_BILL",
      payload: bill,
    });
  };

  // Function to add a bill
  const receiveBill = async (bill: IBillListDTO) => {
    // Dispatch an action to add the bill and its billing positions
    dispatch({
      type: "ADD_BILL",
      payload: bill,
    });
  };

  // Function to add a bill
  const addBill = async (bill: IBillListDTO) => {
    return new Promise(function (resolve, reject) {
      // Display a snackbar with message "Rechnung wird erstellt" for 10 seconds
      let x = enqueueSnackbar(
        (bill.bill.isOffer ? "Angebot" : "Rechnung") + " wird erstellt",
        { variant: "default", autoHideDuration: autoHideDurationDefault }
      );

      let formData = new FormData();
      formData.append(
        "bill",
        JSON.stringify({
          ...bill.bill,
          date: new Date(bill?.bill.date ?? "").toISOString(),
        })
      );
      formData.append(
        "billingPositionsArray",
        JSON.stringify(bill.billingPositions)
      );
      //formData.append("file", bill.file ?? "");
      console.log(
        "billingPositionsArray",
        JSON.stringify(bill.billingPositions)
      );

      connection
        .post(`/bill`, formData)
        .then((res: AxiosResponse<IBillListDTO>) => {
          // Dispatch an action to add the bill and its billing positions
          dispatch({
            type: "ADD_BILL",
            payload: {
              bill: res.data.bill,
              billingPositions: res.data.billingPositions,
            },
          });

          // Close the snackbar and display a success message
          closeSnackbar(x);
          enqueueSnackbar(
            (bill.bill.isOffer ? "Angebot" : "Rechnung") +
              " erfolgreich erstellt.",
            { variant: "success" }
          );

          // Return the bill data
          resolve(res.data);
        })
        .catch((error: any) => {
          // Error handling
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status !== 401) {
              // Display an error message
              enqueueSnackbar(
                "Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.",
                { variant: "error" }
              );
            } else {
              // Display a warning message if the username or password is incorrect
              enqueueSnackbar(
                "Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.",
                { variant: "warning" }
              );
            }
          } else {
            // Display an error message
            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          }
          reject(error);
        });
    });
  };

  const addBillWithBillingPositions = async (
    bill: IBillDTO,
    selectedServices?: Array<number>,
    selectedPositions?: Array<IPosition>
  ) => {
    return new Promise(function (resolve, reject) {
      // Display a snackbar with message "Rechnung wird erstellt" for 10 seconds
      let x = enqueueSnackbar(
        (bill.isOffer ? "Angebot" : "Rechnung") + " wird erstellt",
        { variant: "default", autoHideDuration: autoHideDurationDefault }
      );

      const formData = new FormData();
      formData.append(
        "bill",
        JSON.stringify({
          ...bill,
          generatePdf: true,
          isIncoming: true,
          isReceipt: false,
        })
      );
      formData.append("billingPositionsArray", JSON.stringify([]));
      formData.append("file", "");
      formData.append("selectedServicesJSON", JSON.stringify(selectedServices));
      formData.append("selectedPositions", JSON.stringify(selectedPositions));

      console.log("bill", bill, "formData", formData);

      connection
        .post("/bill", formData)
        .then((res: AxiosResponse<IBillListDTO>) => {
          // Dispatch an action to add the bill and its billing positions
          dispatch({
            type: "ADD_BILL",
            payload: {
              bill: res.data.bill,
              billingPositions: res.data.billingPositions,
            },
          });

          // Close the snackbar and display a success message
          closeSnackbar(x);
          enqueueSnackbar(
            (bill.isOffer ? "Angebot" : "Rechnung") + " erfolgreich erstellt.",
            { variant: "success" }
          );

          // Return the bill data
          resolve(res.data);
        })
        .catch((error: any) => {
          // Error handling
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status !== 401) {
              // Display an error message
              enqueueSnackbar(
                "Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.",
                { variant: "error" }
              );
            } else {
              // Display a warning message if the username or password is incorrect
              enqueueSnackbar(
                "Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.",
                { variant: "warning" }
              );
            }
          } else {
            // Display an error message
            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          }
          reject(error);
        });
    });
  };

  const addReceipt = (bill: IBillListDTO) => {
    return new Promise(function (resolve, reject) {
      let x = enqueueSnackbar("Beleg wird erstellt", {
        variant: "default",
        autoHideDuration: autoHideDurationDefault,
      });

      debugger;

      let formData = new FormData();
      console.log(bill.bill, new Date(bill?.bill.date ?? ""), new Date(""));
      formData.append(
        "bill",
        JSON.stringify({
          ...bill.bill,
          date: bill?.bill.date
            ? new Date(bill?.bill.date).toISOString()
            : new Date("").toISOString(),
        })
      );
      formData.append(
        "billingPositionsArray",
        JSON.stringify(bill.billingPositions)
      );
      //formData.append("file", bill.file ?? "");
      console.log(
        "billingPositionsArray",
        JSON.stringify(bill.billingPositions)
      );

      connection
        .post(`/bill`, formData)
        .then((res: AxiosResponse<IBillListDTO>) => {
          console.log("ADD_BILL", {
            bill: res.data.bill,
            billingPositions: res.data.billingPositions,
          });
          console.log("BILLS", state.bills);
          dispatch({
            type: "ADD_BILL",
            payload: {
              bill: res.data.bill,
              billingPositions: res.data.billingPositions,
            },
          });

          closeSnackbar(x);
          enqueueSnackbar("Beleg erfolgreich erstellt.", {
            variant: "success",
          });

          resolve(res.data);
        })
        .catch((error: any) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);

            if (error.response.status !== 401) {
              enqueueSnackbar(
                "Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.",
                { variant: "error" }
              );
            } else {
              //Wenn Passwort oder Username falsch
              enqueueSnackbar(
                "Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.",
                { variant: "warning" }
              );
            }
          } else {
            // Display an error message
            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          }
          reject(error);
        });
    });
  };

  const updateReceipt = (bill: IBillDTO) => {
    return new Promise(function (resolve, reject) {
      let x = enqueueSnackbar("Beleg wird gespeichert", {
        variant: "default",
        autoHideDuration: autoHideDurationDefault,
      });

      connection
        .put(`/bill`, bill)
        .then((res: AxiosResponse) => {
          dispatch({
            type: "UPDATE_BILL",
            payload: res.data,
          });

          closeSnackbar(x);
          enqueueSnackbar("Beleg erfolgreich bearbeitet.", {
            variant: "success",
          });

          resolve(res.data);
        })
        .catch((error: any) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);

            if (error.response.status !== 401) {
              enqueueSnackbar(
                "Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.",
                { variant: "error" }
              );
            } else {
              //Wenn Passwort oder Username falsch
              enqueueSnackbar(
                "Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.",
                { variant: "warning" }
              );
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js

            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          } else {
            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          }

          reject(error);
        });
    });
  };

  const receiveUpdatedBill = (bill: IBillListDTO) => {
    dispatch({
      type: "UPDATE_BILLDTO",
      payload: bill,
    });
  };

  const receiveDeleteBill = (bill: IBillListDTO) => {
    dispatch({
      type: "DELETE_BILLDTO",
      payload: bill,
    });
  };

  const updateBill = (bill: IBillDTO) => {
    return new Promise(function (resolve, reject) {
      let x = enqueueSnackbar(
        (bill.isOffer ? "Angebot" : "Rechnung") + " wird gespeichert",
        { variant: "default", autoHideDuration: autoHideDurationDefault }
      );

      connection
        .put(`/bill`, bill)
        .then((res: AxiosResponse) => {
          dispatch({
            type: "UPDATE_BILL",
            payload: res.data,
          });

          closeSnackbar(x);
          enqueueSnackbar(
            (bill.isOffer ? "Angebot" : "Rechnung") +
              " erfolgreich bearbeitet.",
            { variant: "success" }
          );

          resolve(res.data);
        })
        .catch((error: any) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);

            if (error.response.status !== 401) {
              enqueueSnackbar(
                "Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.",
                { variant: "error" }
              );
            } else {
              //Wenn Passwort oder Username falsch
              enqueueSnackbar(
                "Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.",
                { variant: "warning" }
              );
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js

            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          } else {
            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          }

          reject(error);
        });
    });
  };

  const markBillCreated = (bill: IBillDTO) => {
    return new Promise(function (resolve, reject) {
      let x = enqueueSnackbar(
        bill.isOffer
          ? "Angebot wird als angenommen markiert"
          : "Rechnung wird als abgesendet markiert",
        { variant: "default", autoHideDuration: autoHideDurationDefault }
      );

      connection
        .put(`/bill/create/?billId=${bill?.id}`)
        .then((res: AxiosResponse) => {
          dispatch({
            type: "UPDATE_BILL",
            payload: res.data,
          });

          closeSnackbar(x);
          enqueueSnackbar(
            bill.isOffer
              ? "Angebot erfolgreich als angenommen markiert."
              : "Rechnung erfolgreich als abgesendet markiert",
            { variant: "success" }
          );

          resolve(res.data);
        })
        .catch((error: any) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);

            if (error.response.status !== 401) {
              enqueueSnackbar(
                "Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.",
                { variant: "error" }
              );
            } else {
              //Wenn Passwort oder Username falsch
              enqueueSnackbar(
                "Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.",
                { variant: "warning" }
              );
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js

            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          } else {
            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          }

          reject(error);
        });
    });
  };

  const markBillTasksGenerated = (bill: IBillDTO) => {
    return new Promise(function (resolve, reject) {
      //let x = enqueueSnackbar((bill.isOffer ? "Angebot wird als angenommen markiert" : "Rechnung wird als abgesendet markiert"), { variant: "default", autoHideDuration: autoHideDurationDefault })

      connection
        .put(`/bill/tasksgenerated?billId=${bill?.id}`)
        .then((res: AxiosResponse) => {
          dispatch({
            type: "UPDATE_BILL",
            payload: res.data,
          });

          //closeSnackbar(x);
          //enqueueSnackbar((bill.isOffer ? "Angebot erfolgreich als angenommen markiert." : "Rechnung erfolgreich als abgesendet markiert"), { variant: "success" })

          resolve(res.data);
        })
        .catch((error: any) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);

            if (error.response.status !== 401) {
              enqueueSnackbar(
                "Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.",
                { variant: "error" }
              );
            } else {
              //Wenn Passwort oder Username falsch
              enqueueSnackbar(
                "Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.",
                { variant: "warning" }
              );
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js

            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          } else {
            enqueueSnackbar(
              "Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.",
              { variant: "error" }
            );
          }

          reject(error);
        });
    });
  };

  const fetchRegistrierkasse = () => {
    connection.get("/registrierkasse").then((res: AxiosResponse) => {
      dispatch({
        type: "SET_REGISTRIERKASSE",
        payload: res.data,
      });
    });
  };

  const fetchRegistrierkasseDatenprotokoll = () => {
    connection
      .get("/registrierkasse/datenprotokoll")
      .then((res: AxiosResponse) => {
        dispatch({
          type: "SET_REGISTRIERKASSEDATENPROTOKOLL",
          payload: res.data,
        });
      });
  };

  const fetchRegistrierkasseZertifikat = () => {
    connection.get("/registrierkasse/zertifikat").then((res: AxiosResponse) => {
      dispatch({
        type: "SET_REGISTRIERKASSEZERTIFIKAT",
        payload: res.data,
      });
    });
  };

  const fetchUnits = () => {
    connection.get("/unit").then((res: AxiosResponse) => {
      dispatch({
        type: "SET_UNITS",
        payload: res.data,
      });
    });
  };

  const fetchBillables = () => {
    connection.get("/bill/getbillables").then((res: AxiosResponse) => {
      dispatch({
        type: "SET_BILLABLES",
        payload: res.data,
      });
    });
  };

  const fetchBillablesAndReturn = (
    customerOrganizationId?: number,
    rounding?: number
  ) => {
    return connection
      .get("/bill/getbillables", {
        params: { customerOrganizationId, rounding },
      })
      .then((response) => {
        const result: Array<IPosition> = response.data;
        return result; // Return the response when the promise resolves
      })
      .catch((error) => {
        console.error("Error fetching billables:", error);
        throw error; // Re-throw the error to handle it in the calling code
      });
  };

  const addRegistrierkasse = (kasse: IRegistrierkasse) => {
    let x = enqueueSnackbar("Registrierkasse wird erstellt", {
      variant: "default",
      autoHideDuration: autoHideDurationDefault,
    });

    connection.post("/registrierkasse", kasse).then((res: AxiosResponse) => {
      enqueueSnackbar("Registrierkasse erfolgreich erstellt", {
        variant: "success",
      });
      closeSnackbar(x);

      dispatch({
        type: "ADD_REGISTRIERKASSE",
        payload: res.data,
      });
    });
  };

  const addBillPrefix = (billPrefix: BillPrefix) => {
    let x = enqueueSnackbar("Rechnungskreis wird erstellt", {
      variant: "default",
      autoHideDuration: autoHideDurationDefault,
    });

    connection
      .post("/billPrefix/create", billPrefix)
      .then((res: AxiosResponse) => {
        enqueueSnackbar("Rechnungskreis erfolgreich erstellt", {
          variant: "success",
        });
        closeSnackbar(x);

        dispatch({
          type: "ADD_BILLPREFIX",
          payload: res.data,
        });
      })
      .catch((error: any) => {
        if (error.response) {
          if (error.response.status === 409) {
            closeSnackbar(x);
            enqueueSnackbar("Rechnungskreis existiert bereits", {
              variant: "error",
            });
          }
        }
      });
  };

  const setBillPrefixes = (billPrefixes: BillPrefixDTO[]) => {
    dispatch({
      type: "SET_BILLPREFIXES",
      payload: billPrefixes,
    });
  }

  const createRegistrierkasseBeleg = (
    beleg: IRegistrierkasseDatenerfassungsprotokoll
  ) => {
    let x = enqueueSnackbar("Beleg wird erstellt", {
      variant: "default",
      autoHideDuration: autoHideDurationDefault,
    });

    return connection
      .post("/registrierkasse", beleg)
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Beleg erfolgreich erstellt", { variant: "success" });
        dispatch({
          type: "ADD_REGISTRIERKASSEDATENPROTOKOLL",
          payload: res.data,
        })
        return res.data;
      })
      .catch((error: AxiosError) => {
        closeSnackbar(x);
        enqueueSnackbar("Ein Fehler ist aufgetreten", { variant: "error" });
        return error;
      });
  };

  //identifikation - Steuernummer oder UID
  //idenfitikationType - Steuernummer 2 | UID 0
  /*const addZertifikat = ({ identifikation, identifikationType }: { identifikation: string, identifikationType: number }) => {
    let x = enqueueSnackbar("Zertifikat wird erstellt", { variant: "default", autoHideDuration: 10000 })

    connection.post('/registrierkasse/zertifikat', { identifikation, identifikationType })
      .then((res: AxiosResponse) => {
        enqueueSnackbar("Registrierkasse erfolgreich erstellt", { variant: "success" });
        closeSnackbar(x);

        dispatch({
          type: "ADD_REGISTRIERKASSE",
          payload: res.data
        })
      })
  }*/

  const [state, dispatch] = useReducer(BillReducer, {
    bills: [],
    billPrefixes: [],
    units: [],
    accounting: undefined,
    billables: [],
    updateBillingPosition: updateBillingPosition,
  });

  useEffect(() => {
    fetchBills();
    fetchRegistrierkasse();
    fetchRegistrierkasseDatenprotokoll();
    fetchRegistrierkasseZertifikat();
    connection.get("/billprefix/").then((res: AxiosResponse) => {
      setBillPrefixes(res.data);
    });
    fetchUnits();
    fetchBillables();
  }, []);

  const { companyData } = useCompanyData();

  const defaultBill: IBillDTO = {
    textBefore: `Sehr geehrte Damen und Herren,\n\nvielen Dank für Ihren Auftrag und das damit verbundene Vertrauen!\n\n\nHiermit stellen wir Ihnen auf der Seite 2 ff angeführte Leistungen zu folgendem Gesamtbetrag in Rechnung:`,
    textAfter: `Bitte überweisen Sie den Rechnungsbetrag unter Angabe der Rechnungsnummer auf das unten angegebene Konto.\n\n\n\nMit freundlichen Grüßen\nIhr Team von ${companyData.name}`,
  };

  const defaultOffer: IBillDTO = {
    textBefore: `Sehr geehrte Damen und Herren,\n\nvielen Dank für Ihr Interesse!\n\n\nHiermit möchten wir Ihnen auf der Seite 2 ff angeführte Leistungen zu folgendem Gesamtbetrag in Angebot stellen:`,
    textAfter: `Bitte kontaktieren Sie uns, sollte es Unklarheiten oder Änderungswünsche geben.\n\n\n\nMit freundlichen Grüßen\nIhr Team von ${companyData.name}`,
  };

  const addBillFromDeliveryNote = (
    deliveryNote: IDeliveryNoteDTO,
    billNumber: number,
    billPrefixId: number
  ) => {
    return new Promise(function (resolve, reject) {
      let x = enqueueSnackbar("Rechnung wird generiert", {
        variant: "default",
        autoHideDuration: autoHideDurationDefault,
      });

      connection
        .post("/bill/FromDeliveryNote", deliveryNote, {
          params: { billNumber: billNumber, billPrefixId: billPrefixId },
        })
        .then((res: AxiosResponse) => {
          enqueueSnackbar("Rechnungskreis erfolgreich generiert", {
            variant: "success",
          });
          closeSnackbar(x);

          dispatch({
            type: "ADD_BILL",
            payload: {
              bill: res.data.bill,
              billingPositions: res.data.billingPositions,
            },
          });

          resolve(res.data.bill);
        })
        .catch((error: any) => {
          closeSnackbar(x);
          enqueueSnackbar("Rechnung konnte leider nicht erstellt werden", {
            variant: "error",
          });

          reject(error);
        });
    });
  };

  useEffect(() => {
    if (companyData.fiscalYear.length > 0) {
      connection
        .get("/bill/accounting", {
          params: {
            start: companyData.fiscalYear[0],
            end: companyData.fiscalYear[1],
          },
        })
        .then((res: AxiosResponse) => {
          setAccounting(res.data);
        });
    }
  }, [companyData.fiscalYear]);

  return (
    <BillContext.Provider
      value={{
        bills: state.bills,
        billPrefixes: state.billPrefixes,
        accounting: state.accounting,
        units: state.units,
        registrierkasse: state.registrierkasse,
        registrierkasseDatenerfassungsprotokoll:
          state.registrierkasseDatenprotokoll,
        registrierkasseZertifikat: state.registrierkasseZertifikat,
        defaultBill: defaultBill,
        defaultOffer: defaultOffer,
        billables: state.billables,
        updateBillingPosition,
        deleteBill,
        addBill,
        addBillWithBillingPositions,
        addBillFromDeliveryNote,
        addReceipt,
        addBillPrefix,
        setBillPrefixes,
        updateBill,
        markBillCreated,
        markBillTasksGenerated,
        updateReceipt,
        createRegistrierkasseBeleg,
        receiveBill,
        receiveUpdatedBill,
        receiveDeleteBill,
        setAccounting,
        fetchBillablesAndReturn,
      }}
    >
      {children}
    </BillContext.Provider>
  );
};

export default BillProvider;

export const useBills = () => useContext(BillContext);
