import React from "react";
import BillsCard from "../../Components/BillCards/BillsCard";
import {
  IBillDTO,
  IBillingPosition,
  IBillListDTO,
} from "../../Interfaces/Bill";

const BillReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_BILLPREFIXES":
      return {
        ...state,
        billPrefixes: action.payload,
      };
    case "SET_ACCOUNTING":
      return {
        ...state,
        accounting: action.payload,
      };
    case "SET_UNITS":
      return {
        ...state,
        units: action.payload,
      };
    case "SET_BILLS":
      return {
        ...state,
        bills: action.payload,
      };
    case "SET_BILLABLES":
      return {
        ...state,
        billables: action.payload,
      };
    case "SET_REGISTRIERKASSE":
      return {
        ...state,
        registrierkasse: action.payload,
      };
    case "SET_REGISTRIERKASSEZERTIFIKAT":
      return {
        ...state,
        registrierkasseZertifikat: action.payload,
      };
    case "SET_REGISTRIERKASSEDATENPROTOKOLL":
      return {
        ...state,
        registrierkasseDatenprotokoll: action.payload,
      };
    case "ADD_BILL":
      return {
        ...state,
        bills: [action.payload, ...state.bills],
      };
    case "ADD_BILLPREFIX":
      return {
        ...state,
        billPrefixes: [action.payload, ...state.billPrefixes],
      };
    case "ADD_REGISTRIERKASSE":
      return {
        ...state,
        registrierkasse: action.payload,
      };
    case "ADD_REGISTRIERKASSEDATENPROTOKOLL":
      return {
        ...state,
        registrierkasseDatenprotokoll: [...state.registrierkasseDatenprotokoll, action.payload],
      }
    case "UPDATE_BILL":
      return {
        ...state,
        bills: state.bills.map((billList: IBillListDTO) => {
          if (billList.bill.id === action.payload.id) {
            return {
              billingPositions: billList.billingPositions,
              bill: action.payload,
            };
          }
          return billList;
        }),
      };
    case "UPDATE_BILLDTO":
      return {
        ...state,
        bills: state.bills.map((billList: IBillListDTO) => {
          if (billList.bill.id === action.payload.bill.id) {
            console.log("UPDATE_BILLDTO", action.payload);
            return action.payload;
          }
          return billList;
        }),
      };
    case "UPDATE_BILLINGPOSITION":
      return {
        ...state,
        bills: state.bills.map((billListDTO: IBillListDTO) => {
          if (billListDTO.bill.id === action.payload.billId) {
            return {
              ...billListDTO,
              billingPositions: [
                ...billListDTO.billingPositions,
                action.payload,
              ],
            };
          }
          return billListDTO;
        }),
      };
    case "DELETE_BILL":
      return {
        ...state,
        bills: state.bills.filter((billList: IBillListDTO) => {
          if (billList.bill.id === action.payload.id) {
            return false;
          }
          return true;
        }),
      };
    case "DELETE_BILLDTO":
      return {
        ...state,
        bills: state.bills.filter((billList: IBillListDTO) => {
          if (billList.bill.id === action.payload.bill.id) {
            return false;
          }
          return true;
        }),
      };
    default:
      return {
        ...state,
      };
  }
};

export default BillReducer;
