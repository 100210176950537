import { AddRounded, SearchRounded } from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useModals } from "../../Contexts/DialogContext/DialogProvider";
import { IServiceDTO } from "../../Interfaces/Service";
import { serviceFormModes } from "../../Pages/Services/Services";
import {
  useGetAllServicesQuery,
  useGetFilteredServicesQuery,
} from "../../redux/apiSlices/Service";
import Service from "../Service/Service";
import {
  Card,
  CardContent,
  CardFooter,
  CardFooterButton,
  CardHeader,
  CardTitle,
} from "../UI/CardNew";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../UI/Collapsible";
import { Input } from "../UI/Input";
import { ServiceFilters } from "./ServiceFilter";
import { useDebounce } from "../../Hooks/useDebounce";
import { card } from "../../Interfaces/Card";
import { useUser } from "../../Contexts/UserContext/UserContext";
import ServiceCardSearchInput from "./ServiceCardSearchInput";

export interface blankcard extends card {
  cta?: boolean;
  ctaTitle?: string;
}

interface IServiceCard {
  setClickedService?: Dispatch<SetStateAction<IServiceDTO>>;
  setFormMode?: Dispatch<SetStateAction<serviceFormModes>>;
}

function ServiceCard({ setClickedService, setFormMode }: IServiceCard) {
  const [showAllServices, setShowAllServices] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchWord(e.target.value);
    },
    []
  );
  const [searchQuery, setSearchQuery] = useState(""); // Holds the actual query
  const [page, setPage] = useState(1);
  const limit = 50;
  const observerRef = useRef<IntersectionObserver | null>(null);

  const { data, isFetching, isLoading, refetch } = useGetFilteredServicesQuery(
    { search: searchQuery, page, limit, fetchAll: showAllServices },
    { refetchOnMountOrArgChange: true }
  );

  const [services, setServices] = useState<IServiceDTO[]>([]);
  const hasMore = data?.hasMore ?? false;

  //Append new data when data.services updates
  useEffect(() => {
    if (!data || !data.services) return;
    setServices((prev) => {
      const existingIds = new Set(prev.map((s) => s.id));
      const newServices = data.services.filter((s) => !existingIds.has(s.id));
      return page === 1 ? data.services : [...prev, ...newServices];
    });
  }, [data, data?.services, page]);

  const lastServiceRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isFetching || !hasMore || !node) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          console.log("Fetching next page...");
          setPage((prev) => prev + 1);
        }
      });

      observerRef.current.observe(node);
    },
    [isFetching, hasMore]
  );

  const { changeVisibility } = useModals();
  const { user } = useUser();

  return (
    <Card>
      <CardHeader className="flex-none">
        <Collapsible className="w-full">
          <div className="flex flex-row items-center justify-between pt-2">
            <CardTitle>Leistungen</CardTitle>
            <div className="flex flex-row gap-2">
              {(user?.organizationRoleName === "Admin" ||
                user?.organizationRoleName === "Owner") && (
                <>
                  {" "}
                  <div
                    className={`rounded-full border ${
                      !showAllServices && "bg-brand-light"
                    }`}
                  >
                    <Tooltip title="Meine Leistungen">
                      <IconButton onClick={() => setShowAllServices(false)}>
                        <PersonIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    className={`rounded-full border ${
                      showAllServices && "bg-brand-light"
                    }`}
                  >
                    <Tooltip title="Alle Leistungen">
                      <IconButton onClick={() => setShowAllServices(true)}>
                        <BusinessIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </>
              )}
              <CollapsibleTrigger asChild>
                <IconButton>
                  <SearchRounded />
                </IconButton>
              </CollapsibleTrigger>
            </div>
          </div>
          <CollapsibleContent className="flex flex-row w-full items-center CollapsibleContent pb-2 pt-2">
            <ServiceCardSearchInput
              onSearch={setSearchQuery}
              searchIsLoading={isLoading || isFetching}
              setPage={setPage}
            />
          </CollapsibleContent>
        </Collapsible>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex column" style={{ gap: "5px" }}>
            {services.length > 0
              ? services.map((service, index) => {
                  const isLastService = index === services.length - 1;
                  return (
                    <div
                      key={service.id}
                      ref={isLastService ? lastServiceRef : null}
                    >
                      <Service
                        isAdminView={showAllServices}
                        backgroundColor={
                          index % 2 === 1 ? "var(--ultra-light)" : undefined
                        }
                        service={service}
                        setClickedService={setClickedService}
                        setFormMode={setFormMode}
                      />
                    </div>
                  );
                })
              : "Keine Leistungen gefunden"}
            {isFetching && (
              <div className="flex w-full items-center justify-center">
                <CircularProgress />
              </div>
            )}
          </div>
        )}
      </CardContent>
      <CardFooter>
        <CardFooterButton
          onClick={() => changeVisibility!("createService", true)}
        >
          <AddRounded fontSize="small" />
          Leistung erstellen
        </CardFooterButton>
      </CardFooter>
    </Card>
  );
}

export default ServiceCard;
